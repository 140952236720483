import { DomHandler } from "primevue/utils";

export class FocusControl {

  /**
   * ドキュメントIDによるフォーカス移動
   * @param event 
   * @param nextId 
   */
	public static setNextFocusById = (event: KeyboardEvent, nextId: string) => {
		console.debug(event);
		console.debug(nextId);
		document.getElementById(nextId)?.focus();
  }

  /**
   * 
   * @param event 
   * @param targetClass 
   */
  public static setNextFocusByClass = (event: KeyboardEvent, targetClass: string) => {
    console.log("setNextFocusByClass = " + targetClass);
    
    if (!targetClass.startsWith(".")) {
      targetClass = "." + targetClass;
    }

    const topNode = this.findInputTopNode(event.target);

    let target = DomHandler.find(topNode, targetClass)[0] as HTMLElement;

    if (target === undefined) {
      const parentNode = this.findInputTopNode(topNode.parentNode);

      target = DomHandler.find(parentNode, targetClass)[0] as HTMLElement;
    }

    if (target === undefined) {
      event.preventDefault();
      return ;
    }

    if (DomHandler.hasClass(target, 'p-password') ) {
      target = DomHandler.find(target, '.p-password-input')[0] as HTMLElement;
    }

    if (DomHandler.hasClass(target, 'p-inputnumber') ) {
      target = DomHandler.find(target, '.p-inputnumber-input')[0] as HTMLElement;
    }

    if (DomHandler.hasClass(target, 'p-calendar')) {
      target = DomHandler.find(target, '.p-inputtext')[0] as HTMLElement;
    }

    if (DomHandler.hasClass(target, 'p-autocomplete')) {
      target = DomHandler.find(target, '.p-inputtext')[0] as HTMLElement;
    }
  
    if (DomHandler.hasClass(target, 'p-dropdown')
    || DomHandler.hasClass(target, 'p-multiselect')
    || DomHandler.hasClass(target, 'p-checkbox')) {
      DomHandler.invokeElementMethod(target, 'click', []);
    } else {
      DomHandler.invokeElementMethod(target, 'focus', []);
      if (DomHandler.hasClass(target, 'p-inputtext')) {
        DomHandler.invokeElementMethod(target, 'select', []);
      }
    }
    event.preventDefault();
  }

  public static findInputTopNode = (element: any) => {
      if (element) {
          let topNode = element;
          while (topNode && !DomHandler.hasClass(topNode, 'input-top')) {
              topNode = topNode.parentElement;
          }

          return topNode;
      }
      else {
          return null;
      }
  }


  /**
   * DataTableのフォーカス移動（Editableの場合）
   * @param event
   */
  public static setNextFocusOnDataTable(event: any) {
    const { field } = event;

    console.log("setNextFocusOnDataTable");
    console.debug(event);

    // // 現在行のノードを取得
    // let trEl = event.originalEvent.srcElement.closest('tr');
    // console.debug(trEl);

    // // 現在列のノードを取得
    // const tdEl = event.originalEvent.srcElement.closest('td');
    // console.debug(tdEl);

    // // スタイルクラスを取得
    // const classList = tdEl.classList as DOMTokenList;

    // let colNo: number = 1;

    // // 現在の列番号を取得
    // for (const value of classList) {
    //     console.debug('classList.value = ' + value);
    //   if (value.startsWith('target-col-')) {
    //     console.debug(value);
    //     const splitNumber = value.split('-')[2];
    //     colNo = Number(splitNumber);
    //     console.debug('colNo = ' + colNo);
    //   }
    // }

    // // 列番号をアップ
    // colNo++;

    // let targetClass = '.target-col-' + colNo.toString();
    // console.log(targetClass);
    // // const parent = closest.parent;
    // // Find no row, go to 1st row

    // let targetNode  = trEl.querySelector(targetClass)

    // console.log(targetNode);

    // if (targetNode === null) {

    //   console.log('targetNode is null');
    //   targetClass = '.target-col-1';
    //   if (trEl.nextElementSibling === null) {
    //     const tbodyEl = trEl.parentNode
    //     console.debug(tbodyEl)
    //     console.log(tbodyEl.querySelector('tr'))
    //     trEl = tbodyEl.querySelector('tr')
    //     console.log("It's a last row and column")
    //     console.debug(trEl);
    //     // const tbodyEl = trEl.parentNode
    //     // console.log(tbodyEl)
    //   } else {
    //     trEl = trEl.nextElementSibling;
    //   }
    //   console.log('targetClass is ' + targetClass);
    //   targetNode  = trEl.querySelector(targetClass)
    //   console.log(targetNode);
    // }

    // targetNode.click();

  }

}
