// defineStoreをインポート
import { defineStore } from 'pinia'
import { IReceiptDetailComponent, IReceiptHistoryComponent } from '@/interface/component/registration/i-registration-receipt-component';
import { IBPaymentComponent, IRoomInformationComponent } from '@/interface/component/registration/i-registration-component';
import { useAuthStore } from '../auth';
import { useRegistrationStore } from './registrationStore';
import { format } from 'date-fns';

export const useRegistrationReceiptDetailDialogStore = defineStore('registrationReceiptDetailDialogStore', {
    state: () => ({
        display: false as boolean,
        noCallback: null as any,
        yesCallback: null as any,
        getReceiptDetails: null as any,
        previewCallback: null as any,
        allDetail: [] as IReceiptDetailComponent[], // 明細編集
        receipt: [] as IReceiptHistoryComponent[],
        previewReceiptId : 0 as number,
        receiptIndex: 0 as number,
        model: 'detail',
        pattern: 1, // 1:領収書付 2:領収書なし 3:見積書
        receiptType: 'REAL', // REAL: 領収書 VIRTUAL: 架空
        pages: [] as any[],
        deletePages: [],
        payments: [] as IBPaymentComponent[],
        historyMode: false as boolean
    }),

    getters: {
        getPages(state) {
            const pages = [] as number[];

            for (let i = 0; i < state.allDetail.length; i++) {
                if (pages.indexOf(state.allDetail[i].billPage) === -1) {
                    pages.push(state.allDetail[i].billPage)
                }
            }

            if (pages.length === 0) {
                pages.push(1);
            }

            return pages.sort();
        },

        // getPayment(state) {
        //     const payments = [] as IReceiptDetailComponent[];

        //     if (state.receipt[state.receiptIndex].receiptDetails === undefined) {
        //         return [];
        //     }

        //     for (let i = 0; i < state.receipt[state.receiptIndex].receiptDetails.length; i++) {
        //         const payment = state.receipt[state.receiptIndex].receiptDetails[i];

        //         if (payment.creditAmount !== undefined && payment.creditAmount > 0) {
        //             payments.push(payment);
        //         }
        //     }

        //     return payments as IReceiptDetailComponent[];
        // }

        targetDateArray(state) {
            const rdStore = useRegistrationStore();

            const result = [] as targetDateArrayType[];

            const addElement = (roomDate : string) => {
                if (result.filter((o => o.id === roomDate)).length === 0) {
                    result.push({ id: roomDate, label: roomDate })
                }
            };

            if (rdStore.getRoomUnit.isRepresentativeRoom === 'YES' 
                && (rdStore.bBasicInformation.payIndivisualOrBulk === 'BULK' || rdStore.bBasicInformation.payIndivisualOrBulk === 'ROOM_FEE')) {
                    
                    for (let index = 0; index < rdStore.bBasicInformation.bRoomUnit.length; index++) {
                        const roomUnit = rdStore.bBasicInformation.bRoomUnit[index];

                        roomUnit.bRoomInformation.forEach(item => {
                            addElement(item.roomDate);
                        });

                        if (roomUnit.nights > 0) {
                            const checkOutDate = format(roomUnit.checkOutDate, 'yyyy/MM/dd');
                            addElement(checkOutDate);
                        }
                    }
                } else {
                    rdStore.getRoomUnit.bRoomInformation.forEach(item => {
                        addElement(item.roomDate);
                    });

                    if (rdStore.getRoomUnit.nights > 0) {
                        const checkOutDate = format(rdStore.getRoomUnit.checkOutDate, 'yyyy/MM/dd');
                        addElement(checkOutDate);
                    }
                }
            
            // for (let index = 0; index < rdStore.getRoomUnit.bRoomInformation.length; index++) {
            //     console.log("index = " + index)
            //     const roomDate = rdStore.getRoomUnit.bRoomInformation[index].roomDate;

            //     result.push({ id: roomDate, label: roomDate })
            // }

            // if (rdStore.getRoomUnit.nights > 0) {
            //     const checkOutDate = format(rdStore.getRoomUnit.checkOutDate, 'yyyy/MM/dd');
            //     result.push({ id: checkOutDate, label: checkOutDate })
            // }

            return result;
        }
    },

    actions: {
        addDetail() {
            return initialiseDetail() as IReceiptDetailComponent;
        }
    }
})

interface targetDateArrayType {
    id: string
    label : string
}

function initializeComponent(): IReceiptHistoryComponent {
    const authStore = useAuthStore();
    return {
        id: 0,
        groupId: authStore.targetStore!.groupId,	//	グループID
        companyId: authStore.targetStore!.companyId!,	//	会社ID
        storeId: authStore.targetStore!.storeId!,	//	店舗ID

        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomUnitId: 0,	//	部屋単位情報ID

        receiptType: "", // レシート区分  REAL: 領収書 VIRTUAL: 架空
        roomId: 0, // 部屋情報ID
        roomNo: "", // 部屋名

        receiptAddress: "", // 領収書宛名
        persons: 0, // 人数
        arraival: "", // 到着日
        departure: "", // 出発日
        receiptNo: "", // 領収書番号
        issuedDateTime: "", // 発行日時
        cashierId: "", // 担当者ID
        cashierName: "", // 担当者

        totalUseAmount: 0, // 利用計
        totalDebitAmount: 0, // 支払計
        totalBalanceAmount: 0, // 請求計
        totalReducedAmount: 0, // 軽減税率対象合計
        totalReducedTaxAmount: 0, // 軽減税率対象税金合計
        totalStandardAmount: 0, // 標準税率対象合計
        totalStandardTaxAmount: 0, // 標準税率対象税金合計
        totalNonTaxAmount: 0, // 非課税合計
        totalStayTaxAmount: 0, // 宿泊税合計
        totalReceiptAmount: 0, // 領収金額
        forPaymentOf: "", // 但し書き 例) 但し、室料として
        rePrintTimes: 0, // 再発行枚数 プレビュー時にカウントアップ
        lang: "JPN", // 言語 JPN：日本語 EN： 英語

        page: 1,
        printHonorificTitle: "YES", // 敬称を印字
        isIssued: 'NOT_ISSUED',
        receiptDetails: [] as IReceiptDetailComponent[]

    }
}

function initialiseDetail(): IReceiptDetailComponent {
    const authStore = useAuthStore();
    return {
        id: 0,
        groupId: authStore.targetStore!.groupId,	//	グループID
        companyId: authStore.targetStore!.companyId!,	//	会社ID
        storeId: authStore.targetStore!.storeId!,	//	店舗ID
    
        receiptHistoryId: 0, // 領収書印刷履歴情報ID
        detailDate: "", // 日付
        detailMark: "", // マーク ※(軽減税率対象)
        detailName: "", // 明細名
        debitAmount: 0, // ご利用金額
        creditAmount: 0, // お支払金額
        remarks: "", // 備考

        useRoomId: 0, // '部屋ID',
        useRoomNo: '', // '部屋名',

        billPage: 1
    } as IReceiptDetailComponent
}