import http from "@/http-common";
import { IRankListResponse } from "@/interface/api/master/i-rank-response";
import { IRankComponent } from "@/interface/component/master/i-rank-component";
import { IUserTragetStoreInfo } from "@/interface/serverData/common/i-user-info";
import { useAuthStore } from "@/store/auth";
import { CConvertor } from "@/utils/c-convertor";

export default class RankService {

    /**
     * Get rank drop down list
     */
    getDropDownList() {

        console.log("RankService getList START");
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        const sendData: { [key: string]: any; } = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
        };

        console.log(JSON.stringify(sendData));

        return new Promise<IRankComponent[]>((resolve, reject) => {
            try {
                http.post("/api/master/rank/getDropDownList", sendData)
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IRankListResponse;
                        console.log(data.returnCode);
                        if (data.returnCode === 200) {
                            const ranks = data.ranks as IRankComponent[];
                            console.log(JSON.stringify(ranks))
                            resolve(ranks);
                        } else {
                            reject(data);
                        }
                    }).catch(response => {
                        console.log("RankService getList  Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }
}
