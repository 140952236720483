// import axios from 'axios';
import http from "@/http-common";
import { ILoginResponse } from "@/interface/api/auth/i-login-response";
import { IStaffLoginResponse } from "@/interface/api/auth/i-staff-login-response";
import { IInternalResponse } from "@/interface/api/i-internal-response";
import { IUserInfo } from "@/interface/serverData/common/i-user-info";
import { useAuthStore } from "@/store/auth";
import { CConvertor } from "@/utils/c-convertor";

export default class AuthService {

    async login<IUserInfo>(login_id: string, password: string) {

        console.log("AuthService login START");

        const getSanctum = () => {
            return new Promise<any>((resolve, reject) => {

                http.get("/sanctum/csrf-cookie").then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                });
            })
        }

        const doLogin = () => {
            return new Promise<ILoginResponse>((resolve, reject) => {

                const sendData: { [key: string]: any; } = {
                    login_id: login_id,
                    password: password
                };


                http.post("/api/login", sendData)
                    .then(response => {
                        // context.commit('SET_IS_AUTH', true);
                        const data = CConvertor.toCamelCaseObject(response.data) as ILoginResponse;
                        console.log(data.returnCode);
                        if (data.returnCode === 200) {
                            console.debug(JSON.stringify(data));
                            // const loginResponse = data.userInfo as unknown as IUserInfo;
                            const loginResponse = data;
                            resolve(loginResponse);
                        } else {
                            reject(data);
                        }
                    }).catch(response => {
                        console.log("login Error");
                        console.log(response);
                        reject(response);
                    });
            })
        };

        return getSanctum()
            .then((data => {
                console.log(data);
                return doLogin();
            }));

    }

    // スタッフ登録番号
    async staffLogin(registNumber: string, userInfo: IUserInfo) {

        console.log("AuthService staffLogin START");

        const sendData: { [key: string]: any; } = {
            group_id: userInfo.groupId,
            company_id: userInfo.companyId,
            store_id: userInfo.storeId,
            regist_number: registNumber,
        };

        return new Promise<IStaffLoginResponse>((resolve, reject) => {
            try {
                http.post("/api/staffLogin", sendData
                ).then(response => {
                    // context.commit('SET_IS_AUTH', true);
                    const data =  CConvertor.toCamelCaseObject(response.data) as IStaffLoginResponse;
                    console.log(data.returnCode);
                    if (data.returnCode === 200) {
                        console.debug(JSON.stringify(data));
                        const loginStaffResponse = data.data as IStaffLoginResponse;
                        resolve(loginStaffResponse);
                    } else {
                        reject(data.returnCode);
                    }
                }).catch(response => {
                    console.log("login Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    // ログアウト
    async logout() {
        console.log("AuthService logout START");

        const sendData: { [key: string]: any; } = {};

        return new Promise<IInternalResponse>((resolve, reject) => {
            try {
                http.post("api/logout", sendData
                ).then(response => {
                    const data = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(data.returnCode);
                    if (data.returnCode === 200) {
                        resolve(data);
                    } else {
                        reject(data.returnCode);
                    }
                }).catch(response => {
                    console.log("login Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }
}