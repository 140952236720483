<template>
	<div class="layout-footer">
		<!-- <img alt="Logo" :src="footerImage()" height="20" class="mr-2" /> -->
		<div class="all-right">
			<span>©2024 X1Studio</span>
		</div>
	</div>
</template>

<script>
import { useAuthStore } from '@/store/auth';
import { format } from 'date-fns';
export default {
	name: "AppFooter",
	data() {
		return {
			auth: useAuthStore(),

		}
	},
	methods: {
		footerImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		},
		format() {
			return format(new Date(this.auth.user.lastLogin), 'yyyy/MM/dd HH:mm');
		}
	},
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		},

		bussinessDate() {
			return this.auth.bussinessDate;
		}
	}
}
</script>

<style scoped>
.date {
	position: absolute;
	top: .8rem;
	right: 10rem;
}

.all-right {
	padding-top: 69px;
	padding-bottom: 39px;
	margin: 0 auto;
	text-align: center;
}
</style>