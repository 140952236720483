import http from "@/http-common";
import { IInternalResponse } from "@/interface/api/i-internal-response";
import { IUserCreateResponse, IUserEditResponse, IUserDeleteResponse, IUserListResponse } from "@/interface/api/master/i-user-response";
import { IUserComponent, IUserInfoComponent } from "@/interface/component/master/i-user-component";
import { IUserTragetStoreInfo } from "@/interface/serverData/common/i-user-info";
import { CConvertor } from "@/utils/c-convertor";
import { ISubmitAuthComponent } from "@/interface/component/common/i-submit-auth-component";
import { IChangePWDComponent } from '@/interface/component/common/i-change-pwd-component';
import { useAuthStore } from "@/store/auth";

export default class UserService {
     /**
     * Get user list
     */
    getList(targetStore: IUserTragetStoreInfo) {
        console.log("UserService getList START");

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
        };

        return new Promise<IUserComponent[]>((resolve, reject) => {
            try {
                http.post("/api/master/user/list", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IUserListResponse;
                        console.log(res.returnCode);
                        if (res.returnCode === 200) {
                            const users = res.data as IUserComponent[];
                            console.log(JSON.stringify(users))
                            resolve(users);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        console.log("UserService getList Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    create(user: IUserComponent, authInfo: ISubmitAuthComponent, authStore: IUserTragetStoreInfo) {

        console.log("UserService create START");
        const sendData: { [key: string]: any; } = {
            name: user.name,
            email: user.email,
            role_type: user.roleType,
            req_store_id: user.storeId,
            memo: user.memo,
            //password: user.password,
            company_id: user.companyId,
            group_id: user.groupId,
            store_ids: user.storeIds,
            submit_user_email: authInfo.email,
            input_by: authInfo.inputBy,
            password: authInfo.password,
            user_id: user.userId,
            user_login_id: authInfo.userLoginId,
            store_id: authStore.storeId,
            req_password: user.password
        };

        return new Promise<IUserComponent>((resolve, reject) => {
            try {

                http.post("/api/master/user/create", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IUserCreateResponse;
                        console.log(res.returnCode);
                        if (res.returnCode === 200) {
                            const user = res.data as IUserComponent;
                            console.log(JSON.stringify(user))
                            resolve(user);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        console.log("UserService create Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    edit(user: IUserComponent, authInfo: ISubmitAuthComponent, authStore: IUserTragetStoreInfo) {

        console.log("UserService edit START");
        const sendData: { [key: string]: any; } = {
            id: user.id,
            name: user.name,
            email: user.email,
            role_type: user.roleType,
            req_store_id: user.storeId,
            memo: user.memo,
            //password: user.password,
            company_id: user.companyId,
            group_id: user.groupId,
            store_ids: user.storeIds,
            submit_user_email: authInfo.email,
            input_by: authInfo.inputBy,
            password: authInfo.password,
            user_id: user.userId,
            user_login_id: authInfo.userLoginId,
            store_id: authStore.storeId,
            req_password: user.password
        };

        return new Promise<IUserComponent>((resolve, reject) => {
            try {

                http.post("/api/master/user/edit", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IUserEditResponse;
                        console.log(res.returnCode);
                        if (res.returnCode === 200) {
                            const user = res.data as IUserComponent;
                            console.log(JSON.stringify(user))
                            resolve(user);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        console.log("UserService edit Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    delete(targetStore: IUserTragetStoreInfo, user: IUserComponent, authInfo: ISubmitAuthComponent) {

        console.log("UserService delete START");
        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            user_id: user.id,
            submit_user_email: authInfo.email,
            input_by: authInfo.inputBy,
            password: authInfo.password,
            user_login_id: authInfo.userLoginId
        };

        return new Promise<IInternalResponse>((resolve, reject) => {
            try {

                http.post("/api/master/user/delete", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IUserDeleteResponse;
                        console.log(res.returnCode);
                        if (res.returnCode === 200) {
                            console.log(JSON.stringify(res))
                            resolve(res.data);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        console.log("UserService delete Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    changePassword(targetStore: IUserTragetStoreInfo, user: IChangePWDComponent) {

        console.log("UserService delete START");
        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            user_id: user.userId,
            password: user.password
        };

        return new Promise<IInternalResponse>((resolve, reject) => {
            try {

                http.post("/api/master/user/change/password", sendData)
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IUserDeleteResponse;
                        if (data.returnCode === 200) {
                            resolve(data);
                        } else {
                            reject(response);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        });
    }

    sendEmail(emial: string) {

        const sendData: { [key: string]: any; } = {
            email: emial
        };

        return new Promise<IInternalResponse>((resolve, reject) => {
            try {

                http.post("/api/master/user/send/email", sendData)
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IUserDeleteResponse;
                        if (data.returnCode === 200) {
                            resolve(data);
                        } else {
                            reject(response);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        });
    }

    verifyPwdUrl(token: string) {

        const sendData: { [key: string]: any; } = {
            token
        };

        return new Promise<IInternalResponse>((resolve, reject) => {
            try {

                http.post("/api/master/user/verify/url", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (res.returnCode === 200) {
                            resolve(res.data);
                        } else {
                            reject(response);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        });
    }

    changePasswordByEmail(user: IChangePWDComponent) {

        console.log("UserService delete START");
        const sendData: { [key: string]: any; } = {
            user_id: user.userId,
            password: user.password,
            token: user.token
        };

        return new Promise<IInternalResponse>((resolve, reject) => {
            try {

                http.post("/api/master/user/change/by/email", sendData)
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IUserDeleteResponse;
                        if (data.returnCode === 200) {
                            resolve(data);
                        } else {
                            reject(response);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        });
    }

    /**
     * 店舗切替
     */
    changeCurrentStore(userId: number, targetStoreId: number) {
        
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        const sendData: { [key: string]: any; } = {
            user_id: userId,
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            target_store_id: targetStoreId
        };

        return new Promise<IUserInfoComponent>((resolve, reject) => {
            try {
                http.post("/api/master/user/change/currentStore", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (res.returnCode === 200) {
                            const data = res.data as IUserInfoComponent;
                            resolve(data);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        });
    }
}