import http from "@/http-common";
import { AxiosRequestConfig } from "axios";
import { format, isSameDay } from 'date-fns';
import { StringControl } from '@/utils/StringControl';
import { CConvertor } from "@/utils/c-convertor";

import { IRoomInformationResponse, IRegistrationOperationHistoryResponse, IRegistrationGroupCustomersResponse, IRegistrationOrdersAndPaymentsResponse, IRegistrationAccountingItemsResponse, IRegistrationBillingAddressItemsResponse } from '@/interface/api/registration/i-registration-response';
import { IRegistrationGuestInfosResponse, ICountryResponse, IRegistrationGuestInfoResponse, IPostalAddressResponse, IRegistrationCustomersResponse, IRegistrationReceiptHistoryResponse, IBFileResponse } from '@/interface/api/registration/i-registration-response';

import { IGuestInformationComponent, IOrderComponent, IRoomInformationComponent, IUseDetailsComponent, IBPaymentComponent, ICountryComponent, IPostalAddressComponent, ICustomerComponent, ICustomerGroupComponent, IRoomUnitComponent} from '@/interface/component/registration/i-registration-component';
import { IOperationHistoryComponent, IRegistrationComponent, IBFileComponent, ICustomerHistoryResultComponent } from "@/interface/component/registration/i-registration-component";
import { IBillingDetailComponent, IPreBillingComponent } from '@/interface/component/bill/i-bill-component';
import { IAccountingItemComponent } from "@/interface/component/master/i-accounting-item-component";
import { IBillingAddressComponent } from "@/interface/component/master/i-billing-address-component";
import { IReceiptHistoryComponent } from "@/interface/component/registration/i-registration-receipt-component";
import { ISubmitAuthComponent } from "@/interface/component/common/i-submit-auth-component";

import { IUserTragetStoreInfo } from '@/interface/serverData/common/i-user-info';
import { IBill, IBillPayment, IBillPlan, IBillDetail } from '@/interface/api/registration/i-registration-checkin-request';
import { IRegistrationCreateRequest, IBBasicInformation, IBRoomUnit, IBRoomInformation, IBGuestInformation, IBOrder, IBPayment, IBFreeMemo, IBArrangement } from '@/interface/api/registration/i-registration-create-request';
import { ICheckInBBasicInformation, ICheckInBRoomUnit, IRegistrationCheckInRequest } from '@/interface/api/registration/i-registration-checkin-request';

import { IInternalResponse } from "@/interface/api/i-internal-response";
import { IRegistrationResponse } from '@/interface/api/registration/i-registration-response';

import { useCheckInDialogStore } from '@/store/registration/checkInDialogStore';
import { useRegistrationStore } from "@/store/registration/registrationStore";
import { useRegistrationSettlementDialogStore } from "@/store/registration/registrationSettlementDialogStore";
import { useRegistrationReceiptDetailDialogStore } from "@/store/registration/registrationReceiptDetailDialogStore";
import { useAuthStore } from "@/store/auth";

export default class RegistrationService {

    getRoomUnitIdsAndFirstRoom(
        bBasicInformationId: number
    ) {
        console.log('RegistrationService getRoomUnitIdsAndFirstRoom START')
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        const sendData: { [key: string]: any; } = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            booking_basic_information_id: bBasicInformationId,
        };

        console.log(JSON.stringify(sendData));

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/getRoomUnitIdsAndFirstRoom", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);
                    console.log(res.data)

                    if (res.returnCode === 200) {
                        const data = res.data as {
                            roomUnitIds : number[],
                            firstRoomUnit: IRoomUnitComponent,
                            basicInfo: IRegistrationComponent
                        }

                        data.basicInfo = data.basicInfo as IRegistrationComponent;

                        data.firstRoomUnit.checkInDate = new Date(data.firstRoomUnit.checkInDate);
                        data.firstRoomUnit.checkOutDate = new Date(data.firstRoomUnit.checkOutDate);

                        const arrivalDatetime = data.firstRoomUnit.arrivalDatetime;
                        if (!StringControl.dateStringIsEmpty(arrivalDatetime)) {
                            data.firstRoomUnit.arrivalDatetime = new Date(data.firstRoomUnit.arrivalDatetime!);
                        }

                        const departureDatetime = data.firstRoomUnit.departureDatetime;
                        if (!StringControl.dateStringIsEmpty(departureDatetime)) {
                            data.firstRoomUnit.departureDatetime = new Date(data.firstRoomUnit.departureDatetime!);
                        }                            

                        const tempBookDeadline = data.firstRoomUnit.tempBookDeadline;
                        if (!StringControl.dateStringIsEmpty(tempBookDeadline)) {
                            data.firstRoomUnit.tempBookDeadline = new Date(data.firstRoomUnit.tempBookDeadline!);
                        }

                        resolve(data);
                    } else {
                        reject(res);
                    }

                }).catch(response => {
                    console.log("getRegistration Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        })
    }

    getRoomUnitIdsByBookingNoAndFirstRoom(
        bookingNo: string
    ) {

        console.log('RegistrationService getRoomUnitIdsByBookingNoAndFirstRoom START')
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        const sendData: { [key: string]: any; } = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            booking_no: bookingNo,
        };

        console.log(JSON.stringify(sendData));

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/getRoomUnitIdsByBookingNoAndFirstRoom", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);
                    console.log(res.data)

                    if (res.returnCode === 200) {
                        const data = res.data as {
                            roomUnitIds : number[],
                            firstRoomUnit: IRoomUnitComponent,
                            basicInfo: IRegistrationComponent
                        }

                        data.basicInfo = data.basicInfo as IRegistrationComponent;

                        data.firstRoomUnit.checkInDate = new Date(data.firstRoomUnit.checkInDate);
                        data.firstRoomUnit.checkOutDate = new Date(data.firstRoomUnit.checkOutDate);

                        const arrivalDatetime = data.firstRoomUnit.arrivalDatetime;
                        if (!StringControl.dateStringIsEmpty(arrivalDatetime)) {
                            data.firstRoomUnit.arrivalDatetime = new Date(data.firstRoomUnit.arrivalDatetime!);
                        }

                        const departureDatetime = data.firstRoomUnit.departureDatetime;
                        if (!StringControl.dateStringIsEmpty(departureDatetime)) {
                            data.firstRoomUnit.departureDatetime = new Date(data.firstRoomUnit.departureDatetime!);
                        }                            

                        const tempBookDeadline = data.firstRoomUnit.tempBookDeadline;
                        if (!StringControl.dateStringIsEmpty(tempBookDeadline)) {
                            data.firstRoomUnit.tempBookDeadline = new Date(data.firstRoomUnit.tempBookDeadline!);
                        }

                        resolve(data);
                    } else {
                        reject(res);
                    }

                }).catch(response => {
                    console.log("getRegistration Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        })
    }

    getRoomUnitIds(
        bBasicInformationId: number
    ) {
        console.log('RegistrationService getRoomUnitIds START')

        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        const sendData: { [key: string]: any; } = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            booking_basic_information_id: bBasicInformationId,
        };

        console.log(JSON.stringify(sendData));

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/getRoomUnitIds", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);
                    console.log(res.data)

                    if (res.returnCode === 200) {
                        const data = res.data as {
                            roomUnitIds : number[],
                            basicInfo: IRegistrationComponent
                        }

                        data.basicInfo = data.basicInfo as IRegistrationComponent;

                        resolve(data);
                    } else {
                        reject(res);
                    }

                }).catch(response => {
                    console.log("getRegistration Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        })
    }

    getRoomUnitById(
        bRoomUnitId: number
    ) {
        console.log('RegistrationService getRoomUnitById START')
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        const sendData: { [key: string]: any; } = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            b_room_unit_id: bRoomUnitId,
        };

        console.log(JSON.stringify(sendData));

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/getRoomUnitById", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);

                    if (res.returnCode === 200) {
                        const roomUnit = res.data as IRoomUnitComponent;

                        roomUnit.checkInDate = new Date(roomUnit.checkInDate);
                        roomUnit.checkOutDate = new Date(roomUnit.checkOutDate);

                        const arrivalDatetime = roomUnit.arrivalDatetime;
                        if (!StringControl.dateStringIsEmpty(arrivalDatetime)) {
                            roomUnit.arrivalDatetime = new Date(roomUnit.arrivalDatetime!);
                        }

                        const departureDatetime = roomUnit.departureDatetime;
                        if (!StringControl.dateStringIsEmpty(departureDatetime)) {
                            roomUnit.departureDatetime = new Date(roomUnit.departureDatetime!);
                        }                            

                        const tempBookDeadline = roomUnit.tempBookDeadline;
                        if (!StringControl.dateStringIsEmpty(tempBookDeadline)) {
                            roomUnit.tempBookDeadline = new Date(roomUnit.tempBookDeadline!);
                        }
                        
                        resolve(roomUnit);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    console.log("getRegistration Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        })
    }


    // レジ情報 詳細取得
    getRegistration (
        targetStore: IUserTragetStoreInfo,
        bBasicInformationId: number
    ) {

        const rdStore = useRegistrationStore();

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            booking_basic_information_id: bBasicInformationId,
        };

        return new Promise<IRegistrationComponent>((resolve, reject) => {
            try {
                http.post("/api/registration", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IRegistrationResponse;
                    console.log(data.returnCode);

                    // チェックイン・チェックアウト日時を変換
                    const bBasicInformation = data.bBasicInformation as IRegistrationComponent;

                    for (const roomUnit of bBasicInformation.bRoomUnit) {
                        roomUnit.checkInDate = new Date(roomUnit.checkInDate);
                        roomUnit.checkOutDate = new Date(roomUnit.checkOutDate);

                        const arrivalDatetime = roomUnit.arrivalDatetime;
                        if (!StringControl.dateStringIsEmpty(arrivalDatetime)) {
                            roomUnit.arrivalDatetime = new Date(roomUnit.arrivalDatetime!);
                        } else {
                            roomUnit.arrivalDatetime = undefined;
                        }

                        const departureDatetime = roomUnit.departureDatetime;
                        if (!StringControl.dateStringIsEmpty(departureDatetime)) {
                            roomUnit.departureDatetime = new Date(roomUnit.departureDatetime!);
                        } else {
                            roomUnit.departureDatetime = undefined;
                        } 

                        const tempBookDeadline = roomUnit.tempBookDeadline;
                        if (!StringControl.dateStringIsEmpty(tempBookDeadline)) {
                            roomUnit.tempBookDeadline = new Date(roomUnit.tempBookDeadline!);
                        }
                    }

                    resolve(bBasicInformation);
                }).catch(response => {
                    console.log("getRegistration Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        })
    }

    getRegistrationByBookingNo (
        targetStore: IUserTragetStoreInfo,
        bookingNo: string
    ) {

        const rdStore = useRegistrationStore();

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            booking_no: bookingNo,
        };

        return new Promise<IRegistrationComponent>((resolve, reject) => {
            try {
                http.post("/api/registration/searchByBookNo", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IRegistrationResponse;
                    console.log(data.returnCode);

                    if (data.returnCode === 200) {
                        // チェックイン・チェックアウト日時を変換
                        const bBasicInformation = data.bBasicInformation as IRegistrationComponent;

                        for (const roomUnit of bBasicInformation.bRoomUnit) {
                            roomUnit.checkInDate = new Date(roomUnit.checkInDate);
                            roomUnit.checkOutDate = new Date(roomUnit.checkOutDate);

                            const arrivalDatetime = roomUnit.arrivalDatetime;
                            if (!StringControl.dateStringIsEmpty(arrivalDatetime)) {
                                roomUnit.arrivalDatetime = new Date(roomUnit.arrivalDatetime!);
                            }

                            const departureDatetime = roomUnit.departureDatetime;
                            if (!StringControl.dateStringIsEmpty(departureDatetime)) {
                                roomUnit.departureDatetime = new Date(roomUnit.departureDatetime!);
                            }                            

                            const tempBookDeadline = roomUnit.tempBookDeadline;
                            if (!StringControl.dateStringIsEmpty(tempBookDeadline)) {
                                roomUnit.tempBookDeadline = new Date(roomUnit.tempBookDeadline!);
                            }
                        }
                        resolve(bBasicInformation);
                    } else {
                        reject(data);
                    }
                }).catch(response => {
                    console.log("getRegistration Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        })
    }

    getUseCondition(
        targetStore: IUserTragetStoreInfo,
        bBasicInformationId: number,
        bRoomUnitId: number
    ) {
        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            b_basic_information_id: bBasicInformationId,
            b_room_unit_id: bRoomUnitId
        };

        return new Promise<IRoomInformationComponent[]>((resolve, reject) => {
            try {
                http.post("/api/registration/getUseCondition", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data = CConvertor.toCamelCaseObject(response.data) as IRoomInformationResponse;
                    console.log(data.returnCode);

                    const bRoomInformations = data.bRoomInformations as IRoomInformationComponent[];

                    resolve(bRoomInformations);
                }).catch(response => {
                    console.log("getUseCondition Error");
                    console.log(response);
                    reject(response);
                });
            } catch (e) {
                console.log(e + "処理失敗");
                reject(e);
            }
        });
    }

    // For registration Store
    createRegistration(
        targetStore: IUserTragetStoreInfo,
        submitAuthInfo: ISubmitAuthComponent
    ) {
        const rdStore = useRegistrationStore();
        const authStore = useAuthStore();

        // 部屋単位情報オブジェクト 作成
        const bRoomUnitArray: IBRoomUnit[] = Array();
        for (let index = 0; index < rdStore.bBasicInformation.bRoomUnit.length; index++) {

            // 部屋情報オブジェクト 作成
            const bRoomInformationArray: IBRoomInformation[] = Array();
            for (let j = 0; j < rdStore.bBasicInformation.bRoomUnit[index].bRoomInformation.length; j++) {
                const bRoomInformationObj: IBRoomInformation = rdStore.bBasicInformation.bRoomUnit[index].bRoomInformation[j];

                const perRoomPaxCount = bRoomInformationObj.roomPaxMaleCount + bRoomInformationObj.roomPaxFemaleCount + 
                bRoomInformationObj.roomChildA70Count + bRoomInformationObj.roomChildB50Count + 
                bRoomInformationObj.roomChildC30Count + bRoomInformationObj.roomChildDnoneCount + 
                bRoomInformationObj.roomChildEcount;

                const bRoomInformation: IBRoomInformation = {
                    roomDate: bRoomInformationObj.roomDate,	//	利用年月日
                    roomTypeId: bRoomInformationObj.roomTypeId,	//	部屋タイプID
                    roomTypeCode: bRoomInformationObj.roomTypeCode,	//	部屋タイプコード
                    roomTypeName: bRoomInformationObj.roomTypeName,	//	部屋タイプ名
                    roomCategory: bRoomInformationObj.roomCategory,	//	部屋カテゴリー

                    viewType: bRoomInformationObj.viewType,	//	部屋眺望
                    smokingOrNonSmoking: bRoomInformationObj.smokingOrNonSmoking,	//	喫煙/禁煙

                    perRoomPaxCount: perRoomPaxCount,	//	1室利用人数
                    roomPaxMaleCount: bRoomInformationObj.roomPaxMaleCount,	//	大人人員(男性)
                    roomPaxFemaleCount: bRoomInformationObj.roomPaxFemaleCount,	//	大人人員(女性)
                    roomChildA70Count: bRoomInformationObj.roomChildA70Count,	//	子供A人数
                    roomChildB50Count: bRoomInformationObj.roomChildB50Count,	//	子供B人数
                    roomChildC30Count: bRoomInformationObj.roomChildC30Count,	//	子供C人数
                    roomChildDnoneCount: bRoomInformationObj.roomChildDnoneCount,	//	子供D人数
                    roomChildEcount: bRoomInformationObj.roomChildEcount,	//	子供E人数
                    roomChildFcount: bRoomInformationObj.roomChildFcount,	//	子供F人数
                    roomChildOtherCount: bRoomInformationObj.roomChildOtherCount,	//	子供その他人数

                    roomChildA70Name: bRoomInformationObj.roomChildA70Name,	//	子供A名称
                    roomChildB50Name: bRoomInformationObj.roomChildB50Name,	//	子供B名称
                    roomChildC30Name: bRoomInformationObj.roomChildC30Name,	//	子供C名称
                    roomChildDnoneName: bRoomInformationObj.roomChildDnoneName,	//	子供D名称
                    roomChildEname: bRoomInformationObj.roomChildEname,	//	子供E名称
                    roomChildFname: bRoomInformationObj.roomChildFname,	//	子供F名称
                    roomChildOtherName: bRoomInformationObj.roomChildOtherName,	//	子供その他名称

                    facilities: bRoomInformationObj.facilities,	//	その他設備

                    assignedRoomTypeId: bRoomInformationObj.assignedRoomTypeId, // 部屋割り後部屋タイプID
                    assignedRoomTypeName: bRoomInformationObj.assignedRoomTypeName, // 部屋割り後部屋タイプ名
                    assignedRoomId: bRoomInformationObj.assignedRoomId, //	部屋割り後客室名/番号 ID
                    assignedRoomNumber: bRoomInformationObj.assignedRoomNumber,	//	部屋割り後客室名/番号

                    roomSpecialRequest: bRoomInformationObj.roomSpecialRequest,	//	客室に対する特別リクエスト

                    travelAgencyId: bRoomInformationObj.travelAgencyId,	// 旅行会社マスタID
                    travelAgencyBookingNumber: bRoomInformationObj.travelAgencyBookingNumber,	//	旅行会社予約(管理)番号
                    travelAgencyBookingDate: bRoomInformationObj.travelAgencyBookingDate,	//	旅行会社予約受付日
                    travelAgencyBookingTime: bRoomInformationObj.travelAgencyBookingTime,	//	旅行会社受付時間
                    travelAgencyReportNumber: bRoomInformationObj.travelAgencyReportNumber,	//	通知番号
                    purposeId: bRoomInformationObj.purposeId,	//	利用目的ID
                    salesRouteId: bRoomInformationObj.salesRouteId,  // 販売経路

                    packageType: bRoomInformationObj.packageType,	//	企画商品区分
                    packagePlanId: bRoomInformationObj.packagePlanId,	//	企画(パッケージ)ID
                    packagePlanName: bRoomInformationObj.packagePlanName,	//	企画(パッケージ)名
                    packagePlanCode: bRoomInformationObj.packagePlanCode,	//	企画(パッケージ)コード
                    packagePlanContent: bRoomInformationObj.packagePlanContent,	//	企画(パッケージ)内容

                    packagePlanPrintTitle: bRoomInformationObj.packagePlanPrintTitle, // 企画(パッケージ)領収書印字名
                    packagePlanPrintTitleE: bRoomInformationObj.packagePlanPrintTitleE, // 企画(パッケージ)領収書印字名 英語
                    
                    perPaxRate: bRoomInformationObj.perPaxRate,	//	大人単価（一人料金)
                    perChildA70Rate: bRoomInformationObj.perChildA70Rate,	//	子供A単価（一人料金)
                    perChildB50Rate: bRoomInformationObj.perChildB50Rate,	//	子供B単価（一人料金)
                    perChildC30Rate: bRoomInformationObj.perChildC30Rate,	//	子供C単価（一人料金)
                    perChildDrate: bRoomInformationObj.perChildDrate,	//	子供D単価（一人料金)
                    perChildErate: bRoomInformationObj.perChildErate,	//	子供E単価（一人料金)
                    perChildFrate: bRoomInformationObj.perChildFrate,	//	子供F単価（一人料金)
                    perChildOtherRate: bRoomInformationObj.perChildOtherRate,	//	子供その他単価（一人料金)

                    roomChildA70RateName: bRoomInformationObj.roomChildA70RateName,	//	子供A名称
                    roomChildB50RateName: bRoomInformationObj.roomChildB50RateName,	//	子供B名称
                    roomChildC30RateName: bRoomInformationObj.roomChildC30RateName,	//	子供C名称
                    roomChildDrateName: bRoomInformationObj.roomChildDrateName,	//	子供D名称
                    roomChildErateName: bRoomInformationObj.roomChildErateName,	//	子供E名称
                    roomChildFrateName: bRoomInformationObj.roomChildFrateName,	//	子供F名称
                    roomChildOtherRateName: bRoomInformationObj.roomChildOtherRateName,	//	子供その他名称

                    totalPerRoomRate: bRoomInformationObj.totalPerRoomRate,	//	1室あたり宿泊料金合計
                    totalPerRoomConsumptionTax: bRoomInformationObj.totalPerRoomConsumptionTax,	//	1室あたり宿泊料金消費税合計
                    totalRoomHotSpringTax: bRoomInformationObj.totalRoomHotSpringTax,	//	1室あたり宿泊料金入湯税合計
                    totalPerRoomServiceFee: bRoomInformationObj.totalPerRoomServiceFee,	//	1室あたり宿泊料金サービス料合計

                    billId: bRoomInformationObj.billId,
                    billPage: bRoomInformationObj.billPage,
                    billStatus: bRoomInformationObj.billStatus,

                    isTotalRateChange: bRoomInformationObj.isTotalRateChange

                    // fareMethodId: bRoomInformationObj.fareMethodId,	//	精算方法

                    // bOrder: orderArray,    // 利用明細情報
                }

                bRoomInformationArray.push(bRoomInformation);
            }

            // 利用明細情報オブジェクト 作成
            const orderArray: IBOrder[] = Array();
            if (rdStore.bBasicInformation.bRoomUnit[index].bOrders !== undefined) {

                for (let k = 0; k < rdStore.bBasicInformation.bRoomUnit[index].bOrders!.length; k++) {
                    const orderObj: IBOrder = rdStore.bBasicInformation.bRoomUnit[index].bOrders![k];
                    const order: IBOrder = {
                        useDate: orderObj.useDate,	// 利用年月日
                        itemId: orderObj.itemId,	// 商品コード
                        itemName: orderObj.itemName,	// 商品名
                        itemNameE: orderObj.itemNameE,	// 商品名（英語）
                        unitPrice: orderObj.unitPrice,	// 単価
                        quantity: orderObj.quantity,	// 数量
                        amount: orderObj.amount,	// 合計金額
                        taxation: orderObj.taxation, // 課税・非課税
                        taxRateType: orderObj.taxRateType,  // 消費税区分 : CON: 標準 REDUCED: 軽減税率
                        taxRate: orderObj.taxRate,	// 消費税率
                        taxAmount: orderObj.taxAmount,	// 消費税額
                        status: orderObj.status,	// ステータス ：PAID: 支払済、UNPAID: 未払
                        // billNo: orderObj.billNo,	// 伝票番号
                        billPage: orderObj.billPage,
                        billRoomId: orderObj.billRoomId,	// 伝票 部屋ID
                        billRoomNo: orderObj.billRoomNo,	// 伝票 部屋NO
                        billStatus: orderObj.billStatus,    // 精算ステータス DONE: 精算済、NOT: 未清算
                        packagePlanId: orderObj.packagePlanId,	// 企画(パッケージ)id
                        packagePlanName: orderObj.packagePlanName,	// 企画(パッケージ)名
                        packagePlanCode: orderObj.packagePlanCode,	// 企画(パッケージ)コード                    }

                        bRoomInformationId: orderObj.bRoomInformationId,	//	部屋情報ID
                        itemPrintTitle: orderObj.itemPrintTitle, // 会計科目領収書印字名
                        itemPrintTitleE: orderObj.itemPrintTitleE, // 会計科目領収書印字名（英語）

                        useRoomId: orderObj.useRoomId,
                        useRoomNo: orderObj.useRoomNo,

                        // バッチ 統計で必要だった
                        perPaxRate: orderObj.perPaxRate,	// 大人単価（一人料金)
                        perChildA70Rate: orderObj.perChildA70Rate,	// 子供A単価（一人料金)
                        perChildB50Rate: orderObj.perChildB50Rate,	// 子供B単価（一人料金)
                        perChildC30Rate: orderObj.perChildC30Rate,	// 子供C単価（一人料金)
                        perChildDrate: orderObj.perChildDrate,	// 子供D単価（一人料金)
                        perChildErate: orderObj.perChildErate,	// 子供E単価（一人料金)
                        perChildFrate: orderObj.perChildFrate,	// 子供F単価（一人料金)
                        perChildOtherRate: orderObj.perChildOtherRate,	// 子供その他単価（一人料金)
                        perRoomPaxCount: orderObj.perRoomPaxCount,	// 1室利用人数
                        roomPaxMaleCount: orderObj.roomPaxMaleCount,	// 大人人員(男性)
                        roomPaxFemaleCount: orderObj.roomPaxFemaleCount,	// 大人人員(女性)
                        roomChildA70Count: orderObj.roomChildA70Count,	// 子供A人数
                        roomChildB50Count: orderObj.roomChildB50Count,	// 子供B人数
                        roomChildC30Count: orderObj.roomChildC30Count,	// 子供C人数
                        roomChildDnoneCount: orderObj.roomChildDnoneCount,	// 子供D人数
                        roomChildEcount: orderObj.roomChildEcount,		// 子供E人数
                        roomChildFcount: orderObj.roomChildFcount,		// 子供F人数
                        roomChildOtherCount: orderObj.roomChildOtherCount,	// 子供その他人数

                        memo: orderObj.memo,

                        bArrangementId: 0,
                        tempBArrangementId: orderObj.tempBArrangementId ?? '',
                        tempBRoomInformationId: orderObj.tempBRoomInformationId,
                        isUnitPriceChange: orderObj.isUnitPriceChange
                    }

                    orderArray.push(order);
                }
            }

            // 手配 作成
            const bArrangementArray: IBArrangement[] = Array();
            if (rdStore.bBasicInformation.bRoomUnit[index].bArrangements !== undefined) {
                for (let k = 0; k < rdStore.bBasicInformation.bRoomUnit[index].bArrangements.length; k++) {
                    const element = rdStore.bBasicInformation.bRoomUnit[index].bArrangements[k];

                    const bArrangement: IBArrangement = {
                        bRoomInformationId: element.bRoomInformationId,
                        useDate: element.useDate,
                        planArrangementId: element.planArrangementId,
                        arrangementId: element.arrangementId,
                        unitPrice: element.unitPrice,
                        quantityType: element.quantityType,
                        quantityRoom: element.quantityRoom,
                        quantityPerson: element.quantityPerson,
                        roomPaxMaleCount: element.roomPaxMaleCount,
                        roomPaxFemaleCount: element.roomPaxFemaleCount,
                        roomChildA70Count: element.roomChildA70Count,
                        roomChildB50Count: element.roomChildB50Count,
                        roomChildC30Count: element.roomChildC30Count,
                        roomChildDnoneCount: element.roomChildDnoneCount,
                        roomChildEcount: element.roomChildEcount,
                        roomChildFcount: element.roomChildFcount,
                        roomChildOtherCount: element.roomChildOtherCount,
                        totalPrice: element.totalPrice,
                        status: element.status,
                        tempBArrangementId: element.tempBArrangementId ?? '',
                        isTicket: element.planArrangementId? element.planArrangementTicketManageType : element.arrangementTicketManageType,
                        isStockManagementType: element.planArrangementId? element.planArrangementStockManagementType : element.arrangementStockManagementType,
                        masterArrangementId: element.planArrangementId? element.planArrangementMasterArrangementId : element.arrangementMasterArrangementId,
                        tempBRoomInformationId: element.tempBRoomInformationId
                    }

                    bArrangementArray.push(bArrangement);
                }
            }

            // お客様情報オブジェクト 作成
            const bGuestInformationArray: IBGuestInformation[] = Array();
            for (let k = 0; k < rdStore.bBasicInformation.bRoomUnit[index].bGuestInformation.length; k++) {
                const element = rdStore.bBasicInformation.bRoomUnit[index].bGuestInformation[k];
                // const guestDateOfBirth = (element.guestDateOfBirth === null) ? "" : format(element.guestDateOfBirth, 'yyyy/MM/dd');
                const bGuestInformation: IBGuestInformation = {
                    guestNameSingleByte: element.guestNameSingleByte,	//	お客様氏名半角カタカナ英字(姓名)
                    guestSurName: element.guestSurName,	//	お客様氏名全角ふりがな(姓)
                    guestKanjiName: element.guestKanjiName,	//	お客様氏名全角漢字(姓名)
                    guestGender: element.guestGender,	//	お客様男女区分
                    guestAge: element.guestAge,	//	お客様年齢
                    // guestDateOfBirth: guestDateOfBirth,	//	お客様生年月日
                    guestPhoneNumber: element.guestPhoneNumber,	//	お客様電話番号
                    guestPhoneNumberType: element.guestPhoneNumberType,	//	お客様電話番号区分
                    guestEmergencyPhoneNumber: element.guestEmergencyPhoneNumber,	//	お客様緊急連絡先
                    guestEmergencyPhoneNumberType: element.guestEmergencyPhoneNumberType,	//	お客様緊急連絡先区分
                    guestEmail: element.guestEmail,	//	お客様Eメール
                    guestCountry: element.guestCountry,	//	お客様国籍
                    guestStateProvidence: element.guestStateProvidence,	//	お客様住所都道府県
                    guestCityName: element.guestCityName,	//	お客様住所区市名
                    guestAddressLine: element.guestAddressLine,	//	お客様住所町村名
                    guestStreetNumber: element.guestStreetNumber,	//	お客様住所番地名
                    guestPostalCode: element.guestPostalCode,	//	お客様住所郵便番号
                    guestAddress1: element.guestAddress1,	//	お客様住所1
                    guestAddress2: element.guestAddress2,	//	お客様住所2
                    companyNameSingleByte: element.companyNameSingleByte,	//	勤務先半角カタカナ英字(姓名)
                    companyKanjiName: element.companyKanjiName,	//	勤務先名全角漢字(姓名)
                    companyPhoneNumber: element.companyPhoneNumber,	//	勤務先電話番号
                    companyPhoneNumberType: element.companyPhoneNumberType,	//	勤務先電話番号 電話区分ID
                    companyEmail: element.companyEmail,	//	勤務先Eメール
                    companyCountry: element.companyCountry,
                    companyStateProvidence: element.companyStateProvidence,	//	勤務先住所都道府県
                    companyPostalCode: element.companyPostalCode,	//	勤務先住所郵便番号
                    companyAddress1: element.companyAddress1,	//	勤務先住所１
                    companyAddress2: element.companyAddress2,	//	勤務先住所２
                    rankId: element.rankId,	//	ランクID
                    customerId: element.customerId,	//	顧客ID
                    memberId: element.memberId,	//	メンバーID
                    expressId: element.expressId,	//	エクスプレスメンバーID
                    type: element.type,
                    issuingCountry: element.issuingCountry,
                    passportNo: element.passportNo,
                    surname: element.surname,
                    givenName: element.givenName,
                    nationality: element.nationality,
                    dateOfBirth: element.dateOfBirth,
                    sex: element.sex,
                    registeredDomicile: element.registeredDomicile,
                    dateOfIssue: element.dateOfIssue,
                    dateOfExpiry: element.dateOfExpiry,
                    filePath: element.filePath,
                    receiptAddress: element.receiptAddress
                }

                bGuestInformationArray.push(bGuestInformation);
            }

            // フリーメモ 作成
            const bFreeMemoArray: IBFreeMemo[] = Array();
            if (rdStore.bBasicInformation.bRoomUnit[index].bFreeMemos !== undefined) {
                for (let i = 0; i < rdStore.bBasicInformation.bRoomUnit[index].bFreeMemos!.length; i++) {
                    const element = rdStore.bBasicInformation.bRoomUnit[index].bFreeMemos![i];

                    const bFreeMemo: IBFreeMemo = {
                        freeMemoId: element.freeMemoId,  // フリーメモID
                        detail: element.detail, // 詳細
                        freeMemoTitle: element.freeMemoTitle,
                        isConfirm: element.isConfirm // 確認しました
                    }

                    bFreeMemoArray.push(bFreeMemo);
                }
            }

            // 部屋単位情報作成
            const element = rdStore.bBasicInformation.bRoomUnit[index];

            let tempBookDeadline = '';
            if (!StringControl.dateStringIsEmpty(element.tempBookDeadline)) {
                tempBookDeadline = format(element.tempBookDeadline!, 'yyyy/MM/dd');
            }

            const bRoomUnit: IBRoomUnit = {
                status: element.status === 'TEMP_BOOK'? 'TEMP_BOOK' : 'BOOK',	//	予約ステータス
                checkInDate: format(element.checkInDate, 'yyyy/MM/dd'),	//	チェックイン日
                checkInTime: element.checkInTime,	//	チェックイン時間
                checkOutDate: format(element.checkOutDate, 'yyyy/MM/dd'),	//	チェックアウト日
                checkOutTime: element.checkOutTime,	//	チェックアウト時間
                nights: element.nights,	//	宿泊日数
                memo: element.memo,	//	予約メモ情報

                bRoomInformation: bRoomInformationArray,	// 部屋情報
                bRoomInformationForDel: [],

                bGuestInformation: bGuestInformationArray,   // お客様情報
                bGuestInformationForDel: [],

                bArrangement: bArrangementArray, // 手配
                bArrangementForDel: [],

                bOrder: orderArray,    // 利用明細情報
                tempBookDeadline:  tempBookDeadline, // 仮予約期限
                isRepresentativeRoom: element.isRepresentativeRoom,
                otaxNotCounted: element.otaxNotCounted,
                bFreeMemo: bFreeMemoArray // フリーメモ
            }

            bRoomUnitArray.push(bRoomUnit);

        }
        

        // Create BasicInformation Object
        const bBasicInformation: IBBasicInformation = {
            status: "BOOK",	//	予約ステータス
            bookingNo: rdStore.bBasicInformation.bookingNo,	//	予約番号
            guestOrGroupNameSingleByte: rdStore.bBasicInformation.guestOrGroupNameSingleByte,	//	団体名または代表者氏名(半角)
            guestOrGroupNameKanjiName: rdStore.bBasicInformation.guestOrGroupNameKanjiName,	//	団体名または代表者氏名 漢字
            guestOrGroupNamePhoneNumber: rdStore.bBasicInformation.guestOrGroupNamePhoneNumber,	//	団体名または代表者電話番号
            guestOrGroupNamePhoneNumberType: rdStore.bBasicInformation.guestOrGroupNamePhoneNumberType, // 団体名または代表者電話番号区分
            // guestOrGroupNamePhoneNumber2: rdStore.bBasicInformation.guestOrGroupNamePhoneNumber2,	//	団体名または代表者電話番号２
            // guestOrGroupNamePhoneNumber2Type: rdStore.bBasicInformation.guestOrGroupNamePhoneNumber2Type, // 団体名または代表者電話番号2区分
            bRoomUnit: bRoomUnitArray,	// 部屋単位情報
            payIndivisualOrBulk: rdStore.bBasicInformation.payIndivisualOrBulk,
            groupNameSingleByte: rdStore.bBasicInformation.groupNameSingleByte,
            groupType: rdStore.bBasicInformation.groupType ?? 'I',
        };

        if (rdStore.bBasicInformation.customerGroupId) {
            if (!rdStore.bBasicInformation.groupNameSingleByte) {
                bBasicInformation.customerGroupId = undefined;
            } else {
                bBasicInformation.customerGroupId = rdStore.bBasicInformation.customerGroupId;
            }
        }


        const sendData: IRegistrationCreateRequest = {
            groupId: targetStore.groupId,	//	グループID
            companyId: targetStore.companyId!,	//	会社ID
            storeId: targetStore.storeId!,	//	店舗ID
            submitUserEmail: submitAuthInfo.email,
            inputBy: submitAuthInfo.inputBy,	//	入力者ID
            password: submitAuthInfo.password,
            machine_no: authStore.machineId!,    // 端末ID
            bBasicInformation: bBasicInformation	// 基本情報
        };

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/create", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);
                    if (res.returnCode === 200) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    console.log("createRegistration Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });

    }

    editRegistration(
        targetStore: IUserTragetStoreInfo,
        submitAuthInfo: ISubmitAuthComponent
    ) {

        console.log("RegistrationService editRegistration START");

        const rdStore = useRegistrationStore();
        const authStore = useAuthStore();
        console.log(rdStore.bBasicInformation.bRoomUnit);
        // 部屋単位情報オブジェクト 作成
        const bRoomUnitArray: IBRoomUnit[] = Array();
        for (let index = 0; index < rdStore.bBasicInformation.bRoomUnit.length; index++) {

            // 部屋情報オブジェクト 作成
            const bRoomInformationArray: IBRoomInformation[] = Array();

            for (let j = 0; j < rdStore.bBasicInformation.bRoomUnit[index].bRoomInformation.length; j++) {
                const bRoomInformationObj: IBRoomInformation = rdStore.bBasicInformation.bRoomUnit[index].bRoomInformation[j];

                const perRoomPaxCount = bRoomInformationObj.roomPaxMaleCount + bRoomInformationObj.roomPaxFemaleCount + 
                bRoomInformationObj.roomChildA70Count + bRoomInformationObj.roomChildB50Count + 
                bRoomInformationObj.roomChildC30Count + bRoomInformationObj.roomChildDnoneCount + 
                bRoomInformationObj.roomChildEcount;

                // 部屋情報
                const bRoomInformation: IBRoomInformation = {
                    id: bRoomInformationObj.id,
                    roomDate: bRoomInformationObj.roomDate,	//	利用年月日
                    roomTypeId: bRoomInformationObj.roomTypeId,	//	部屋タイプID
                    roomTypeCode: bRoomInformationObj.roomTypeCode,	//	部屋タイプコード
                    roomTypeName: bRoomInformationObj.roomTypeName,	//	部屋タイプ名
                    roomCategory: bRoomInformationObj.roomCategory,	//	部屋カテゴリー

                    viewType: bRoomInformationObj.viewType,	//	部屋眺望
                    smokingOrNonSmoking: bRoomInformationObj.smokingOrNonSmoking,	//	喫煙/禁煙

                    perRoomPaxCount: perRoomPaxCount,	//	1室利用人数
                    roomPaxMaleCount: bRoomInformationObj.roomPaxMaleCount,	//	大人人員(男性)
                    roomPaxFemaleCount: bRoomInformationObj.roomPaxFemaleCount,	//	大人人員(女性)
                    roomChildA70Count: bRoomInformationObj.roomChildA70Count,	//	子供A人数
                    roomChildB50Count: bRoomInformationObj.roomChildB50Count,	//	子供B人数
                    roomChildC30Count: bRoomInformationObj.roomChildC30Count,	//	子供C人数
                    roomChildDnoneCount: bRoomInformationObj.roomChildDnoneCount,	//	子供D人数
                    roomChildEcount: bRoomInformationObj.roomChildEcount,	//	子供E人数
                    roomChildFcount: bRoomInformationObj.roomChildFcount,	//	子供F人数
                    roomChildOtherCount: bRoomInformationObj.roomChildOtherCount,	//	子供その他人数

                    roomChildA70Name: bRoomInformationObj.roomChildA70Name,	//	子供A名称
                    roomChildB50Name: bRoomInformationObj.roomChildB50Name,	//	子供B名称
                    roomChildC30Name: bRoomInformationObj.roomChildC30Name,	//	子供C名称
                    roomChildDnoneName: bRoomInformationObj.roomChildDnoneName,	//	子供D名称
                    roomChildEname: bRoomInformationObj.roomChildEname,	//	子供E名称
                    roomChildFname: bRoomInformationObj.roomChildFname,	//	子供F名称
                    roomChildOtherName: bRoomInformationObj.roomChildOtherName,	//	子供その他名称

                    facilities: bRoomInformationObj.facilities,	//	その他設備

                    assignedRoomTypeId: bRoomInformationObj.assignedRoomTypeId, // 部屋割り後部屋タイプID
                    assignedRoomTypeName: bRoomInformationObj.assignedRoomTypeName, // 部屋割り後部屋タイプ名
                    assignedRoomId: bRoomInformationObj.assignedRoomId, //	部屋割り後客室名/番号 ID
                    assignedRoomNumber: bRoomInformationObj.assignedRoomNumber,	//	部屋割り後客室名/番号

                    roomSpecialRequest: bRoomInformationObj.roomSpecialRequest,	//	客室に対する特別リクエスト

                    travelAgencyId: bRoomInformationObj.travelAgencyId,	// 旅行会社マスタID
                    travelAgencyBookingNumber: bRoomInformationObj.travelAgencyBookingNumber,	//	旅行会社予約(管理)番号
                    travelAgencyBookingDate: bRoomInformationObj.travelAgencyBookingDate,	//	旅行会社予約受付日
                    travelAgencyBookingTime: bRoomInformationObj.travelAgencyBookingTime,	//	旅行会社受付時間
                    travelAgencyReportNumber: bRoomInformationObj.travelAgencyReportNumber,	//	通知番号
                    purposeId: bRoomInformationObj.purposeId,	//	利用目的ID
                    salesRouteId: bRoomInformationObj.salesRouteId, // 販売経路

                    packageType: bRoomInformationObj.packageType,	//	企画商品区分
                    packagePlanId: bRoomInformationObj.packagePlanId,	//	企画(パッケージ)ID
                    packagePlanName: bRoomInformationObj.packagePlanName,	//	企画(パッケージ)名
                    packagePlanCode: bRoomInformationObj.packagePlanCode,	//	企画(パッケージ)コード
                    packagePlanContent: bRoomInformationObj.packagePlanContent,	//	企画(パッケージ)内容
                    
                    packagePlanPrintTitle: bRoomInformationObj.packagePlanPrintTitle, // 企画(パッケージ)領収書印字名
                    packagePlanPrintTitleE: bRoomInformationObj.packagePlanPrintTitleE, // 企画(パッケージ)領収書印字名 英語
                    
                    perPaxRate: bRoomInformationObj.perPaxRate,	//	大人単価（一人料金)
                    perChildA70Rate: bRoomInformationObj.perChildA70Rate,	//	子供A単価（一人料金)
                    perChildB50Rate: bRoomInformationObj.perChildB50Rate,	//	子供B単価（一人料金)
                    perChildC30Rate: bRoomInformationObj.perChildC30Rate,	//	子供C単価（一人料金)
                    perChildDrate: bRoomInformationObj.perChildDrate,	//	子供D単価（一人料金)
                    perChildErate: bRoomInformationObj.perChildErate,	//	子供E単価（一人料金)
                    perChildFrate: bRoomInformationObj.perChildFrate,	//	子供F単価（一人料金)
                    perChildOtherRate: bRoomInformationObj.perChildOtherRate,	//	子供その他単価（一人料金)

                    roomChildA70RateName: bRoomInformationObj.roomChildA70RateName,	//	子供A名称
                    roomChildB50RateName: bRoomInformationObj.roomChildB50RateName,	//	子供B名称
                    roomChildC30RateName: bRoomInformationObj.roomChildC30RateName,	//	子供C名称
                    roomChildDrateName: bRoomInformationObj.roomChildDrateName,	//	子供D名称
                    roomChildErateName: bRoomInformationObj.roomChildErateName,	//	子供E名称
                    roomChildFrateName: bRoomInformationObj.roomChildFrateName,	//	子供F名称
                    roomChildOtherRateName: bRoomInformationObj.roomChildOtherRateName,	//	子供その他名称

                    totalPerRoomRate: bRoomInformationObj.totalPerRoomRate,	//	1室あたり宿泊料金合計
                    totalPerRoomConsumptionTax: bRoomInformationObj.totalPerRoomConsumptionTax,	//	1室あたり宿泊料金消費税合計
                    totalRoomHotSpringTax: bRoomInformationObj.totalRoomHotSpringTax,	//	1室あたり宿泊料金入湯税合計
                    totalPerRoomServiceFee: bRoomInformationObj.totalPerRoomServiceFee,	//	1室あたり宿泊料金サービス料合計

                    billId: bRoomInformationObj.billId,
                    billPage: bRoomInformationObj.billPage,
                    billStatus: bRoomInformationObj.billStatus,

                    isTotalRateChange: bRoomInformationObj.isTotalRateChange

                    // fareMethodId: bRoomInformationObj.fareMethodId,	//	精算方法

                    // bOrder: orderArray,    // 利用明細情報
                }

                bRoomInformationArray.push(bRoomInformation);
            }

            // 利用明細情報オブジェクト 作成
            const orderArray: IBOrder[] = Array();
            if (rdStore.bBasicInformation.bRoomUnit[index].bOrders !== undefined) {
                for (let k = 0; k < rdStore.bBasicInformation.bRoomUnit[index].bOrders!.length; k++) {
                    const orderObj: IBOrder = rdStore.bBasicInformation.bRoomUnit[index].bOrders![k];
                    const order: IBOrder = {
                        id: orderObj.id,
                        useDate: orderObj.useDate,	// 利用年月日
                        itemId: orderObj.itemId,	// 商品コード
                        itemName: orderObj.itemName,	// 商品名
                        itemNameE: orderObj.itemNameE,	// 商品名（英語）
                        unitPrice: orderObj.unitPrice,	// 単価
                        quantity: orderObj.quantity,	// 数量
                        amount: orderObj.amount,	// 合計金額
                        taxation: orderObj.taxation, // 課税・非課税
                        taxRateType: orderObj.taxRateType,  // 消費税区分 : CON: 標準 REDUCED: 軽減税率
                        taxRate: orderObj.taxRate,	// 消費税率
                        taxAmount: orderObj.taxAmount,	// 消費税額
                        status: orderObj.status,	// ステータス ：PAID: 支払済、UNPAID: 未払
                        // billNo: orderObj.billNo,	// 伝票番号
                        billRoomId: orderObj.billRoomId,	// 伝票 部屋ID
                        billRoomNo: orderObj.billRoomNo,	// 伝票 部屋NO

                        billPage: orderObj.billPage,
                        billStatus: orderObj.billStatus,    // 精算ステータス DONE: 精算済、NOT: 未清算

                        packagePlanId: orderObj.packagePlanId,	// 企画(パッケージ)id
                        packagePlanName: orderObj.packagePlanName,	// 企画(パッケージ)名
                        packagePlanCode: orderObj.packagePlanCode,	// 企画(パッケージ)コード                    }

                        bRoomInformationId: orderObj.bRoomInformationId,	//	部屋情報ID
                        itemPrintTitle: orderObj.itemPrintTitle, // 会計科目領収書印字名
                        itemPrintTitleE: orderObj.itemPrintTitleE, // 会計科目領収書印字名（英語）

                        useRoomId: orderObj.useRoomId,
                        useRoomNo: orderObj.useRoomNo,

                        // バッチ 統計で必要だった
                        perPaxRate: orderObj.perPaxRate,	// 大人単価（一人料金)
                        perChildA70Rate: orderObj.perChildA70Rate,	// 子供A単価（一人料金)
                        perChildB50Rate: orderObj.perChildB50Rate,	// 子供B単価（一人料金)
                        perChildC30Rate: orderObj.perChildC30Rate,	// 子供C単価（一人料金)
                        perChildDrate: orderObj.perChildDrate,	// 子供D単価（一人料金)
                        perChildErate: orderObj.perChildErate,	// 子供E単価（一人料金)
                        perChildFrate: orderObj.perChildFrate,	// 子供F単価（一人料金)
                        perChildOtherRate: orderObj.perChildOtherRate,	// 子供その他単価（一人料金)
                        perRoomPaxCount: orderObj.perRoomPaxCount,	// 1室利用人数
                        roomPaxMaleCount: orderObj.roomPaxMaleCount,	// 大人人員(男性)
                        roomPaxFemaleCount: orderObj.roomPaxFemaleCount,	// 大人人員(女性)
                        roomChildA70Count: orderObj.roomChildA70Count,	// 子供A人数
                        roomChildB50Count: orderObj.roomChildB50Count,	// 子供B人数
                        roomChildC30Count: orderObj.roomChildC30Count,	// 子供C人数
                        roomChildDnoneCount: orderObj.roomChildDnoneCount,	// 子供D人数
                        roomChildEcount: orderObj.roomChildEcount,		// 子供E人数
                        roomChildFcount: orderObj.roomChildFcount,		// 子供F人数
                        roomChildOtherCount: orderObj.roomChildOtherCount,	// 子供その他人数

                        memo: orderObj.memo,

                        bArrangementId: orderObj.bArrangementId,
                        tempBArrangementId: orderObj.tempBArrangementId ?? '', // 手配の情報
                        tempBRoomInformationId: orderObj.tempBRoomInformationId,
                        isUnitPriceChange: orderObj.isUnitPriceChange
                    }

                    const isRoomInfoForDel = rdStore.bBasicInformation.bRoomUnit[index].bRoomInformationForDel
                    && rdStore.bBasicInformation.bRoomUnit[index].bRoomInformationForDel!.indexOf(orderObj.bRoomInformationId) > -1 && orderObj.id! !== 0 && orderObj.id! !== undefined;

                    const isNotPackagePlan = orderObj.packagePlanId === undefined || orderObj.packagePlanId === 0 || orderObj.packagePlanId === null;

                    if (!(isRoomInfoForDel && !isNotPackagePlan)) {
                        orderArray.push(order);
                    }
                }
            }

            const paymentArray: IBPayment[] = Array();
            if (rdStore.bBasicInformation.bRoomUnit[index].bPayments !== undefined) {
                for (let k = 0; k < rdStore.bBasicInformation.bRoomUnit[index].bPayments!.length; k++) {
                    const paymentObj: IBPayment = rdStore.bBasicInformation.bRoomUnit[index].bPayments![k];
                    const payment: IBPayment = {
                        id: paymentObj.id,
                        paymentDate: paymentObj.paymentDate,	// 入金日
                        
                        billId: paymentObj.billId,	//	領収情報ID
                        billPage: paymentObj.billPage,	//	ビルページ
                        billRoomId: paymentObj.billRoomId,	// 伝票 部屋ID
                        billRoomNo: paymentObj.billRoomNo,	// 伝票 部屋NO
                        billStatus: paymentObj.billStatus,	// 精算ステータス DONE: 精算済、NOT: 未清算

                        paymentAmount: paymentObj.paymentAmount,	// 支払金額
                        paymentBRoomUnitId: paymentObj.paymentBRoomUnitId,  // 支払部屋単位ID

                        accountingItemId: paymentObj.accountingItemId,  // 会計科目ID
                        accountingItemName: paymentObj.accountingItemName,  // 会計科目名
                        accountingItemNameE: paymentObj.accountingItemNameE,  // 会計科目名（英語）
                        accountingItemPrintTitle: paymentObj.accountingItemPrintTitle,  // 会計科目領収書印字名
                        accountingItemPrintTitleE: paymentObj.accountingItemPrintTitleE,  // 会計科目領収書印字名（英語）
                        accountingItemInterfaceCd01: paymentObj.accountingItemInterfaceCd01, // 外部連携コード1
                        accountingItemInterfaceCd02: paymentObj.accountingItemInterfaceCd02,  // 外部連携コード2
                        accountingItemPaymentType: paymentObj.accountingItemPaymentType, 

                        billingAddressId: paymentObj.billingAddressId,	// 請求先情報ID
                        billingAddressName: paymentObj.billingAddressName,	// 請求先名
                        
                        useRoomId: paymentObj.useRoomId,
                        useRoomNo: paymentObj.useRoomNo,	// 利用部屋名
                        memo: paymentObj.memo //備考
                    }

                    paymentArray.push(payment);
                }
            }

            // お客様情報オブジェクト 作成
            const bGuestInformationArray: IBGuestInformation[] = Array();
            for (let k = 0; k < rdStore.bBasicInformation.bRoomUnit[index].bGuestInformation.length; k++) {
                const element = rdStore.bBasicInformation.bRoomUnit[index].bGuestInformation[k];
                // const guestDateOfBirth = (element.guestDateOfBirth === null) ? "" : format(element.guestDateOfBirth, 'yyyy/MM/dd');
                const bGuestInformation: IBGuestInformation = {
                    id: element.id,
                    guestNameSingleByte: element.guestNameSingleByte,	//	お客様氏名半角カタカナ英字(姓名)
                    guestSurName: element.guestSurName,	//	お客様氏名全角ふりがな(姓)
                    guestKanjiName: element.guestKanjiName,	//	お客様氏名全角漢字(姓名)
                    guestGender: element.guestGender,	//	お客様男女区分
                    guestAge: element.guestAge,	//	お客様年齢
                    // guestDateOfBirth: guestDateOfBirth,	//	お客様生年月日
                    guestPhoneNumber: element.guestPhoneNumber,	//	お客様電話番号
                    guestPhoneNumberType: element.guestPhoneNumberType,	//	お客様電話番号区分
                    guestEmergencyPhoneNumber: element.guestEmergencyPhoneNumber,	//	お客様緊急連絡先
                    guestEmergencyPhoneNumberType: element.guestEmergencyPhoneNumberType,	//	お客様緊急連絡先区分
                    guestEmail: element.guestEmail,	//	お客様Eメール
                    guestCountry: element.guestCountry,	//	お客様国籍
                    guestStateProvidence: element.guestStateProvidence,	//	お客様住所都道府県
                    guestCityName: element.guestCityName,	//	お客様住所区市名
                    guestAddressLine: element.guestAddressLine,	//	お客様住所町村名
                    guestStreetNumber: element.guestStreetNumber,	//	お客様住所番地名
                    guestPostalCode: element.guestPostalCode,	//	お客様住所郵便番号
                    guestAddress1: element.guestAddress1,	//	お客様住所1
                    guestAddress2: element.guestAddress2,	//	お客様住所2
                    companyNameSingleByte: element.companyNameSingleByte,	//	勤務先半角カタカナ英字(姓名)
                    companyKanjiName: element.companyKanjiName,	//	勤務先名全角漢字(姓名)
                    companyPhoneNumber: element.companyPhoneNumber,	//	勤務先電話番号
                    companyPhoneNumberType: element.companyPhoneNumberType,	//	勤務先電話番号 電話区分ID
                    companyEmail: element.companyEmail,	//	勤務先Eメール
                    companyCountry: element.companyCountry?element.companyCountry:'',
                    companyStateProvidence: element.companyStateProvidence,	//	勤務先住所都道府県
                    companyPostalCode: element.companyPostalCode,	//	勤務先住所郵便番号
                    companyAddress1: element.companyAddress1,	//	勤務先住所１
                    companyAddress2: element.companyAddress2,	//	勤務先住所２
                    rankId: element.rankId,	//	ランクID
                    customerId: element.customerId,	//	顧客ID
                    memberId: element.memberId,	//	メンバーID
                    expressId: element.expressId,	//	エクスプレスメンバーID
                    type: element.type,
                    issuingCountry: element.issuingCountry,
                    passportNo: element.passportNo,
                    surname: element.surname,
                    givenName: element.givenName,
                    nationality: element.nationality,
                    dateOfBirth: element.dateOfBirth,
                    sex: element.sex,
                    registeredDomicile: element.registeredDomicile,
                    dateOfIssue: element.dateOfIssue,
                    dateOfExpiry: element.dateOfExpiry,
                    filePath: element.filePath?element.filePath:'',
                    receiptAddress: element.receiptAddress
                }

                if (element.guestNameSingleByte === '') {
                    continue;
                }

                bGuestInformationArray.push(bGuestInformation);
            }

            // フリーメモ
            const bFreeMemoArray: IBFreeMemo[] = Array();
            if (rdStore.bBasicInformation.bRoomUnit[index].bFreeMemos !== undefined) {
                for (let i = 0; i < rdStore.bBasicInformation.bRoomUnit[index].bFreeMemos!.length; i++) {
                    const element = rdStore.bBasicInformation.bRoomUnit[index].bFreeMemos![i];

                    const bFreeMemo: IBFreeMemo = {
                        id: element.id,
                        freeMemoId: element.freeMemoId,  // フリーメモID
                        detail: element.detail, // 詳細
                        freeMemoTitle: element.freeMemoTitle,
                        isConfirm: element.isConfirm // 確認しました
                    }

                    bFreeMemoArray.push(bFreeMemo);
                }
            }

            // 手配情報作成
            const bArrangementArray: IBArrangement[] = Array();
            if (rdStore.bBasicInformation.bRoomUnit[index].bArrangements !== undefined) {
                for (let k = 0; k < rdStore.bBasicInformation.bRoomUnit[index].bArrangements.length; k++) {
                    const element = rdStore.bBasicInformation.bRoomUnit[index].bArrangements[k];

                    const bArrangement: IBArrangement = {
                        id: element.id ?? 0,
                        bRoomInformationId: element.bRoomInformationId,
                        useDate: element.useDate,
                        planArrangementId: element.planArrangementId,
                        arrangementId: element.arrangementId,
                        unitPrice: element.unitPrice,
                        quantityType: element.quantityType,
                        quantityRoom: element.quantityRoom,
                        quantityPerson: element.quantityPerson,
                        roomPaxMaleCount: element.roomPaxMaleCount,
                        roomPaxFemaleCount: element.roomPaxFemaleCount,
                        roomChildA70Count: element.roomChildA70Count,
                        roomChildB50Count: element.roomChildB50Count,
                        roomChildC30Count: element.roomChildC30Count,
                        roomChildDnoneCount: element.roomChildDnoneCount,
                        roomChildEcount: element.roomChildEcount,
                        roomChildFcount: element.roomChildFcount,
                        roomChildOtherCount: element.roomChildOtherCount,
                        totalPrice: element.totalPrice,
                        status: element.status,
                        tempBArrangementId: element.tempBArrangementId ?? '',
                        isTicket: element.planArrangementId? element.planArrangementTicketManageType : element.arrangementTicketManageType,
                        isStockManagementType: element.planArrangementId? element.planArrangementStockManagementType : element.arrangementStockManagementType,
                        masterArrangementId: element.planArrangementId? element.planArrangementMasterArrangementId : element.arrangementMasterArrangementId,
                        tempBRoomInformationId: element.tempBRoomInformationId
                    }

                    bArrangementArray.push(bArrangement);
                }
            }


            // 部屋単位情報作成
            const element = rdStore.bBasicInformation.bRoomUnit[index];

            let tempBookDeadline = '';
            if (!StringControl.dateStringIsEmpty(element.tempBookDeadline)) {
                tempBookDeadline = format(element.tempBookDeadline!, 'yyyy/MM/dd');
            }

            const bRoomUnit: IBRoomUnit = {
                id: element.id,
                status: element.status,	//	予約ステータス
                checkInDate: format(element.checkInDate, 'yyyy/MM/dd'),	//	チェックイン日
                checkInTime: element.checkInTime ?? '',	//	チェックイン時間
                checkOutDate: format(element.checkOutDate, 'yyyy/MM/dd'),	//	チェックアウト日
                checkOutTime: element.checkOutTime ?? '',	//	チェックアウト時間
                nights: element.nights,	//	宿泊日数
                memo: element.memo,	//	予約メモ情報

                bRoomInformation: bRoomInformationArray,	// 部屋情報
                bRoomInformationForDel: element.bRoomInformationForDel,

                bGuestInformation: bGuestInformationArray,   // お客様情報
                bGuestInformationForDel: element.bGuestInformationForDel,

                bOrder: orderArray,    // 利用明細情報
                bOrdersForDel: element.bOrdersForDel,

                bPayment: paymentArray,
                bPaymentsForDel: element.bPaymentsForDel,

                tempBookDeadline:  tempBookDeadline, // 仮予約期限
                isRepresentativeRoom: element.isRepresentativeRoom,
                otaxNotCounted: element.otaxNotCounted,
                bFreeMemo: bFreeMemoArray, // フリーメモ
                bFreeMemoForDel: element.bFreeMemoForDel,

                bArrangement: bArrangementArray, // 手配
                bArrangementForDel: element.bArrangementForDel,
            }

            bRoomUnitArray.push(bRoomUnit);

        }

        // edit BasicInformation Object
        const bBasicInformation: IBBasicInformation = {
            id: rdStore.bBasicInformation.id,
            status: rdStore.bBasicInformation.status,	//	予約ステータス
            bookingNo: rdStore.bBasicInformation.bookingNo,	//	予約番号
            guestOrGroupNameSingleByte: rdStore.bBasicInformation.guestOrGroupNameSingleByte,	//	団体名または代表者氏名(半角)
            guestOrGroupNameKanjiName: rdStore.bBasicInformation.guestOrGroupNameKanjiName,	//	団体名または代表者氏名 漢字
            guestOrGroupNamePhoneNumber: rdStore.bBasicInformation.guestOrGroupNamePhoneNumber,	//	団体名または代表者電話番号
            guestOrGroupNamePhoneNumberType: rdStore.bBasicInformation.guestOrGroupNamePhoneNumberType, // 団体名または代表者電話番号区分
            // guestOrGroupNamePhoneNumber2: rdStore.bBasicInformation.guestOrGroupNamePhoneNumber2,	//	団体名または代表者電話番号２
            // guestOrGroupNamePhoneNumber2Type: rdStore.bBasicInformation.guestOrGroupNamePhoneNumber2Type, // 団体名または代表者電話番号2区分
            bRoomUnit: bRoomUnitArray,	// 部屋単位情報
            payIndivisualOrBulk: rdStore.bBasicInformation.payIndivisualOrBulk,

            cancelRoom: rdStore.bBasicInformation.cancelRoom ?? '',
            cancelSettle: rdStore.bBasicInformation.cancelSettle ?? '',
            groupNameSingleByte: rdStore.bBasicInformation.groupNameSingleByte,
            groupType: rdStore.bBasicInformation.groupType ?? 'I',
        };

        if (rdStore.bBasicInformation.customerGroupId){
            if (!rdStore.bBasicInformation.groupNameSingleByte) {
                bBasicInformation.customerGroupId = undefined;
            } else {
                bBasicInformation.customerGroupId = rdStore.bBasicInformation.customerGroupId;
            }
        }

        rdStore.bBasicInformation.cancelRoom = '';

        const sendData: IRegistrationCreateRequest = {
            groupId: targetStore.groupId,	//	グループID
            companyId: targetStore.companyId!,	//	会社ID
            storeId: targetStore.storeId!,	//	店舗ID
            submitUserEmail: submitAuthInfo.email,
            inputBy: submitAuthInfo.inputBy,	//	入力者ID
            password: submitAuthInfo.password,
            machine_no: authStore.machineId!,    // 端末ID
            bBasicInformation: bBasicInformation	// 基本情報
        };

        console.log(JSON.stringify(sendData));

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/edit", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);
                    if (res.returnCode === 200) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    console.log("editRegistration Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });

    }

    cancelRegistration(
        targetStore: IUserTragetStoreInfo,
        submitAuthInfo: ISubmitAuthComponent,
        reason: string
    ) {
        const rdStore = useRegistrationStore();
        const authStore = useAuthStore();

        // 部屋単位情報オブジェクト 作成
        const bRoomUnitArray: IBRoomUnit[] = Array();
        for (let index = 0; index < rdStore.bBasicInformation.bRoomUnit.length; index++) {

            // 部屋情報オブジェクト 作成
            const bRoomInformationArray: IBRoomInformation[] = Array();

            for (let j = 0; j < rdStore.bBasicInformation.bRoomUnit[index].bRoomInformation.length; j++) {
                const bRoomInformationObj: IBRoomInformation = rdStore.bBasicInformation.bRoomUnit[index].bRoomInformation[j];

                const perRoomPaxCount = bRoomInformationObj.roomPaxMaleCount + bRoomInformationObj.roomPaxFemaleCount + 
                bRoomInformationObj.roomChildA70Count + bRoomInformationObj.roomChildB50Count + 
                bRoomInformationObj.roomChildC30Count + bRoomInformationObj.roomChildDnoneCount + 
                bRoomInformationObj.roomChildEcount;

                const bRoomInformation: IBRoomInformation = {
                    id: bRoomInformationObj.id,
                    roomDate: bRoomInformationObj.roomDate,	//	利用年月日
                    roomTypeId: bRoomInformationObj.roomTypeId,	//	部屋タイプID
                    roomTypeCode: bRoomInformationObj.roomTypeCode,	//	部屋タイプコード
                    roomTypeName: bRoomInformationObj.roomTypeName,	//	部屋タイプ名
                    roomCategory: bRoomInformationObj.roomCategory,	//	部屋カテゴリー

                    viewType: bRoomInformationObj.viewType,	//	部屋眺望
                    smokingOrNonSmoking: bRoomInformationObj.smokingOrNonSmoking,	//	喫煙/禁煙

                    perRoomPaxCount: perRoomPaxCount,	//	1室利用人数
                    roomPaxMaleCount: bRoomInformationObj.roomPaxMaleCount,	//	大人人員(男性)
                    roomPaxFemaleCount: bRoomInformationObj.roomPaxFemaleCount,	//	大人人員(女性)
                    roomChildA70Count: bRoomInformationObj.roomChildA70Count,	//	子供A人数
                    roomChildB50Count: bRoomInformationObj.roomChildB50Count,	//	子供B人数
                    roomChildC30Count: bRoomInformationObj.roomChildC30Count,	//	子供C人数
                    roomChildDnoneCount: bRoomInformationObj.roomChildDnoneCount,	//	子供D人数
                    roomChildEcount: bRoomInformationObj.roomChildEcount,	//	子供E人数
                    roomChildFcount: bRoomInformationObj.roomChildFcount,	//	子供F人数
                    roomChildOtherCount: bRoomInformationObj.roomChildOtherCount,	//	子供その他人数

                    roomChildA70Name: bRoomInformationObj.roomChildA70Name,	//	子供A名称
                    roomChildB50Name: bRoomInformationObj.roomChildB50Name,	//	子供B名称
                    roomChildC30Name: bRoomInformationObj.roomChildC30Name,	//	子供C名称
                    roomChildDnoneName: bRoomInformationObj.roomChildDnoneName,	//	子供D名称
                    roomChildEname: bRoomInformationObj.roomChildEname,	//	子供E名称
                    roomChildFname: bRoomInformationObj.roomChildFname,	//	子供F名称
                    roomChildOtherName: bRoomInformationObj.roomChildOtherName,	//	子供その他名称

                    facilities: bRoomInformationObj.facilities,	//	その他設備

                    // キャンセル場合ので、部屋割自動解除
                    assignedRoomTypeId: bRoomInformationObj.assignedRoomTypeId, // 部屋割り後部屋タイプID
                    assignedRoomTypeName: bRoomInformationObj.assignedRoomTypeName, // 部屋割り後部屋タイプ名
                    assignedRoomId: bRoomInformationObj.assignedRoomId, //	部屋割り後客室名/番号 ID
                    assignedRoomNumber: bRoomInformationObj.assignedRoomNumber,	//	部屋割り後客室名/番号

                    roomSpecialRequest: bRoomInformationObj.roomSpecialRequest,	//	客室に対する特別リクエスト

                    travelAgencyId: bRoomInformationObj.travelAgencyId,	// 旅行会社マスタID
                    travelAgencyBookingNumber: bRoomInformationObj.travelAgencyBookingNumber,	//	旅行会社予約(管理)番号
                    travelAgencyBookingDate: bRoomInformationObj.travelAgencyBookingDate,	//	旅行会社予約受付日
                    travelAgencyBookingTime: bRoomInformationObj.travelAgencyBookingTime,	//	旅行会社受付時間
                    travelAgencyReportNumber: bRoomInformationObj.travelAgencyReportNumber,	//	通知番号
                    purposeId: bRoomInformationObj.purposeId,	//	利用目的ID
                    salesRouteId: bRoomInformationObj.salesRouteId, // 販売経路

                    packageType: bRoomInformationObj.packageType,	//	企画商品区分
                    packagePlanId: bRoomInformationObj.packagePlanId,	//	企画(パッケージ)ID
                    packagePlanName: bRoomInformationObj.packagePlanName,	//	企画(パッケージ)名
                    packagePlanCode: bRoomInformationObj.packagePlanCode,	//	企画(パッケージ)コード
                    packagePlanContent: bRoomInformationObj.packagePlanContent,	//	企画(パッケージ)内容
                    
                    packagePlanPrintTitle: bRoomInformationObj.packagePlanPrintTitle, // 企画(パッケージ)領収書印字名
                    packagePlanPrintTitleE: bRoomInformationObj.packagePlanPrintTitleE, // 企画(パッケージ)領収書印字名 英語
                    
                    perPaxRate: bRoomInformationObj.perPaxRate,	//	大人単価（一人料金)
                    perChildA70Rate: bRoomInformationObj.perChildA70Rate,	//	子供A単価（一人料金)
                    perChildB50Rate: bRoomInformationObj.perChildB50Rate,	//	子供B単価（一人料金)
                    perChildC30Rate: bRoomInformationObj.perChildC30Rate,	//	子供C単価（一人料金)
                    perChildDrate: bRoomInformationObj.perChildDrate,	//	子供D単価（一人料金)
                    perChildErate: bRoomInformationObj.perChildErate,	//	子供E単価（一人料金)
                    perChildFrate: bRoomInformationObj.perChildFrate,	//	子供F単価（一人料金)
                    perChildOtherRate: bRoomInformationObj.perChildOtherRate,	//	子供その他単価（一人料金)

                    roomChildA70RateName: bRoomInformationObj.roomChildA70RateName,	//	子供A名称
                    roomChildB50RateName: bRoomInformationObj.roomChildB50RateName,	//	子供B名称
                    roomChildC30RateName: bRoomInformationObj.roomChildC30RateName,	//	子供C名称
                    roomChildDrateName: bRoomInformationObj.roomChildDrateName,	//	子供D名称
                    roomChildErateName: bRoomInformationObj.roomChildErateName,	//	子供E名称
                    roomChildFrateName: bRoomInformationObj.roomChildFrateName,	//	子供F名称
                    roomChildOtherRateName: bRoomInformationObj.roomChildOtherRateName,	//	子供その他名称

                    totalPerRoomRate: bRoomInformationObj.totalPerRoomRate,	//	1室あたり宿泊料金合計
                    totalPerRoomConsumptionTax: bRoomInformationObj.totalPerRoomConsumptionTax,	//	1室あたり宿泊料金消費税合計
                    totalRoomHotSpringTax: bRoomInformationObj.totalRoomHotSpringTax,	//	1室あたり宿泊料金入湯税合計
                    totalPerRoomServiceFee: bRoomInformationObj.totalPerRoomServiceFee,	//	1室あたり宿泊料金サービス料合計

                    billId: bRoomInformationObj.billId,
                    billPage: bRoomInformationObj.billPage,
                    billStatus: bRoomInformationObj.billStatus,
                    isTotalRateChange: bRoomInformationObj.isTotalRateChange
                }

                bRoomInformationArray.push(bRoomInformation);
            }

            // 利用明細情報オブジェクト 作成
            const orderArray: IBOrder[] = Array();

            if (rdStore.bBasicInformation.bRoomUnit[index].bOrders !== undefined) {
                for (let k = 0; k < rdStore.bBasicInformation.bRoomUnit[index].bOrders!.length; k++) {
                    const orderObj: IBOrder = rdStore.bBasicInformation.bRoomUnit[index].bOrders![k];
                    const order: IBOrder = {
                        id: orderObj.id,
                        useDate: orderObj.useDate,	// 利用年月日
                        itemId: orderObj.itemId,	// 商品コード
                        itemName: orderObj.itemName,	// 商品名
                        itemNameE: orderObj.itemNameE,	// 商品名（英語）
                        unitPrice: orderObj.unitPrice,	// 単価
                        quantity: orderObj.quantity,	// 数量
                        amount: orderObj.amount,	// 合計金額
                        taxation: orderObj.taxation, // 課税・非課税
                        taxRateType: orderObj.taxRateType,  // 消費税区分 : CON: 標準 REDUCED: 軽減税率
                        taxRate: orderObj.taxRate,	// 消費税率
                        taxAmount: orderObj.taxAmount,	// 消費税額
                        status: orderObj.status,	// ステータス ：PAID: 支払済、UNPAID: 未払
                        // billNo: orderObj.billNo,	// 伝票番号
                        billRoomId: orderObj.billRoomId,	// 伝票 部屋ID
                        billRoomNo: orderObj.billRoomNo,	// 伝票 部屋NO
                        billStatus: orderObj.billStatus,    // 精算ステータス DONE: 精算済、NOT: 未清算
                        packagePlanId: orderObj.packagePlanId,	// 企画(パッケージ)id
                        packagePlanName: orderObj.packagePlanName,	// 企画(パッケージ)名
                        packagePlanCode: orderObj.packagePlanCode,	// 企画(パッケージ)コード                    }

                        bRoomInformationId: orderObj.bRoomInformationId,	//	部屋情報ID
                        itemPrintTitle: orderObj.itemPrintTitle, // 会計科目領収書印字名
                        itemPrintTitleE: orderObj.itemPrintTitleE, // 会計科目領収書印字名（英語）

                        useRoomId: orderObj.useRoomId,
                        useRoomNo: orderObj.useRoomNo,

                        // バッチ 統計で必要だった
                        perPaxRate: orderObj.perPaxRate,	// 大人単価（一人料金)
                        perChildA70Rate: orderObj.perChildA70Rate,	// 子供A単価（一人料金)
                        perChildB50Rate: orderObj.perChildB50Rate,	// 子供B単価（一人料金)
                        perChildC30Rate: orderObj.perChildC30Rate,	// 子供C単価（一人料金)
                        perChildDrate: orderObj.perChildDrate,	// 子供D単価（一人料金)
                        perChildErate: orderObj.perChildErate,	// 子供E単価（一人料金)
                        perChildFrate: orderObj.perChildFrate,	// 子供F単価（一人料金)
                        perChildOtherRate: orderObj.perChildOtherRate,	// 子供その他単価（一人料金)
                        perRoomPaxCount: orderObj.perRoomPaxCount,	// 1室利用人数
                        roomPaxMaleCount: orderObj.roomPaxMaleCount,	// 大人人員(男性)
                        roomPaxFemaleCount: orderObj.roomPaxFemaleCount,	// 大人人員(女性)
                        roomChildA70Count: orderObj.roomChildA70Count,	// 子供A人数
                        roomChildB50Count: orderObj.roomChildB50Count,	// 子供B人数
                        roomChildC30Count: orderObj.roomChildC30Count,	// 子供C人数
                        roomChildDnoneCount: orderObj.roomChildDnoneCount,	// 子供D人数
                        roomChildEcount: orderObj.roomChildEcount,		// 子供E人数
                        roomChildFcount: orderObj.roomChildFcount,		// 子供F人数
                        roomChildOtherCount: orderObj.roomChildOtherCount,	// 子供その他人数

                        memo: orderObj.memo,

                        bArrangementId: orderObj.bArrangementId,
                        tempBArrangementId: orderObj.tempBArrangementId ?? '',
                        tempBRoomInformationId: orderObj.tempBRoomInformationId,
                        isUnitPriceChange: orderObj.isUnitPriceChange
                    }

                    orderArray.push(order);
                }
            }

            const paymentArray: IBPayment[] = Array();
            if (rdStore.bBasicInformation.bRoomUnit[index].bPayments !== undefined) {
                for (let k = 0; k < rdStore.bBasicInformation.bRoomUnit[index].bPayments!.length; k++) {
                    const paymentObj: IBPayment = rdStore.bBasicInformation.bRoomUnit[index].bPayments![k];
                    const payment: IBPayment = {
                        id: paymentObj.id,
                        paymentDate: paymentObj.paymentDate,	// 入金日
                        
                        billId: paymentObj.billId,	//	領収情報ID
                        billPage: paymentObj.billPage,	//	ビルページ
                        billRoomId: paymentObj.billRoomId,	// 伝票 部屋ID
                        billRoomNo: paymentObj.billRoomNo,	// 伝票 部屋NO
                        billStatus: paymentObj.billStatus,	// 精算ステータス DONE: 精算済、NOT: 未清算

                        paymentAmount: paymentObj.paymentAmount,	// 支払金額
                        paymentBRoomUnitId: paymentObj.paymentBRoomUnitId,  // 支払部屋単位ID

                        accountingItemId: paymentObj.accountingItemId,  // 会計科目ID
                        accountingItemName: paymentObj.accountingItemName,  // 会計科目名
                        accountingItemNameE: paymentObj.accountingItemNameE,  // 会計科目名（英語）
                        accountingItemPrintTitle: paymentObj.accountingItemPrintTitle,  // 会計科目領収書印字名
                        accountingItemPrintTitleE: paymentObj.accountingItemPrintTitleE,  // 会計科目領収書印字名（英語）
                        accountingItemInterfaceCd01: paymentObj.accountingItemInterfaceCd01, // 外部連携コード1
                        accountingItemInterfaceCd02: paymentObj.accountingItemInterfaceCd02,  // 外部連携コード2
                        accountingItemPaymentType: paymentObj.accountingItemPaymentType, 

                        billingAddressId: paymentObj.billingAddressId,	// 請求先情報ID
                        billingAddressName: paymentObj.billingAddressName,	// 請求先名
                        
                        useRoomId: paymentObj.useRoomId,
                        useRoomNo: paymentObj.useRoomNo,	// 利用部屋名
                        memo: paymentObj.memo //備考
                    }

                    if (rdStore.bBasicInformation.bRoomUnit[index].bPaymentsForDel === undefined || 
                        !rdStore.bBasicInformation.bRoomUnit[index].bPaymentsForDel?.includes(paymentObj.id!)) {
                        paymentArray.push(payment);
                    }
                }
            }

            // お客様情報オブジェクト 作成
            const bGuestInformationArray: IBGuestInformation[] = Array();
            for (let k = 0; k < rdStore.bBasicInformation.bRoomUnit[index].bGuestInformation.length; k++) {
                const element = rdStore.bBasicInformation.bRoomUnit[index].bGuestInformation[k];
                // const guestDateOfBirth = (element.guestDateOfBirth === null) ? "" : format(element.guestDateOfBirth, 'yyyy/MM/dd');
                const bGuestInformation: IBGuestInformation = {
                    id: element.id,
                    guestNameSingleByte: element.guestNameSingleByte,	//	お客様氏名半角カタカナ英字(姓名)
                    guestSurName: element.guestSurName,	//	お客様氏名全角ふりがな(姓)
                    guestKanjiName: element.guestKanjiName,	//	お客様氏名全角漢字(姓名)
                    guestGender: element.guestGender,	//	お客様男女区分
                    guestAge: element.guestAge,	//	お客様年齢
                    // guestDateOfBirth: guestDateOfBirth,	//	お客様生年月日
                    guestPhoneNumber: element.guestPhoneNumber,	//	お客様電話番号
                    guestPhoneNumberType: element.guestPhoneNumberType,	//	お客様電話番号区分
                    guestEmergencyPhoneNumber: element.guestEmergencyPhoneNumber,	//	お客様緊急連絡先
                    guestEmergencyPhoneNumberType: element.guestEmergencyPhoneNumberType,	//	お客様緊急連絡先区分
                    guestEmail: element.guestEmail,	//	お客様Eメール
                    guestCountry: element.guestCountry,	//	お客様国籍
                    guestStateProvidence: element.guestStateProvidence,	//	お客様住所都道府県
                    guestCityName: element.guestCityName,	//	お客様住所区市名
                    guestAddressLine: element.guestAddressLine,	//	お客様住所町村名
                    guestStreetNumber: element.guestStreetNumber,	//	お客様住所番地名
                    guestPostalCode: element.guestPostalCode,	//	お客様住所郵便番号
                    guestAddress1: element.guestAddress1,	//	お客様住所1
                    guestAddress2: element.guestAddress2,	//	お客様住所2
                    companyNameSingleByte: element.companyNameSingleByte,	//	勤務先半角カタカナ英字(姓名)
                    companyKanjiName: element.companyKanjiName,	//	勤務先名全角漢字(姓名)
                    companyPhoneNumber: element.companyPhoneNumber,	//	勤務先電話番号
                    companyPhoneNumberType: element.companyPhoneNumberType,	//	勤務先電話番号 電話区分ID
                    companyEmail: element.companyEmail,	//	勤務先Eメール
                    companyStateProvidence: element.companyStateProvidence,	//	勤務先住所都道府県
                    companyPostalCode: element.companyPostalCode,	//	勤務先住所郵便番号
                    companyAddress1: element.companyAddress1,	//	勤務先住所１
                    companyAddress2: element.companyAddress2,	//	勤務先住所２
                    rankId: element.rankId,	//	ランクID
                    customerId: element.customerId,	//	顧客ID
                    memberId: element.memberId,	//	メンバーID
                    expressId: element.expressId,	//	エクスプレスメンバーID
                    receiptAddress: element.receiptAddress
                }

                if (element.guestNameSingleByte === '') {
                    continue;
                }

                bGuestInformationArray.push(bGuestInformation);
            }


            // フリーメモ
            const bFreeMemoArray: IBFreeMemo[] = Array();
            if (rdStore.bBasicInformation.bRoomUnit[index].bFreeMemos !== undefined) {
                for (let i = 0; i < rdStore.bBasicInformation.bRoomUnit[index].bFreeMemos!.length; i++) {
                    const element = rdStore.bBasicInformation.bRoomUnit[index].bFreeMemos![i];

                    const bFreeMemo: IBFreeMemo = {
                        id: element.id,
                        freeMemoId: element.freeMemoId,  // フリーメモID
                        detail: element.detail, // 詳細
                        freeMemoTitle: element.freeMemoTitle,
                        isConfirm: element.isConfirm // 確認しました
                    }

                    bFreeMemoArray.push(bFreeMemo);
                }
            }

            // 手配情報作成
            const bArrangementArray: IBArrangement[] = Array();
            if (rdStore.bBasicInformation.bRoomUnit[index].bArrangements !== undefined) {
                for (let k = 0; k < rdStore.bBasicInformation.bRoomUnit[index].bArrangements.length; k++) {
                    const element = rdStore.bBasicInformation.bRoomUnit[index].bArrangements[k];

                    const bArrangement: IBArrangement = {
                        id: element.id ?? 0,
                        bRoomInformationId: element.bRoomInformationId,
                        useDate: element.useDate,
                        planArrangementId: element.planArrangementId,
                        arrangementId: element.arrangementId,
                        unitPrice: element.unitPrice,
                        quantityType: element.quantityType,
                        quantityRoom: element.quantityRoom,
                        quantityPerson: element.quantityPerson,
                        roomPaxMaleCount: element.roomPaxMaleCount,
                        roomPaxFemaleCount: element.roomPaxFemaleCount,
                        roomChildA70Count: element.roomChildA70Count,
                        roomChildB50Count: element.roomChildB50Count,
                        roomChildC30Count: element.roomChildC30Count,
                        roomChildDnoneCount: element.roomChildDnoneCount,
                        roomChildEcount: element.roomChildEcount,
                        roomChildFcount: element.roomChildFcount,
                        roomChildOtherCount: element.roomChildOtherCount,
                        totalPrice: element.totalPrice,
                        status: element.status,
                        tempBArrangementId: element.tempBArrangementId ?? '',
                        isTicket: element.planArrangementId? element.planArrangementTicketManageType : element.arrangementTicketManageType,
                        isStockManagementType: element.planArrangementId? element.planArrangementStockManagementType : element.arrangementStockManagementType,
                        masterArrangementId: element.planArrangementId? element.planArrangementMasterArrangementId : element.arrangementMasterArrangementId,
                        tempBRoomInformationId: element.tempBRoomInformationId
                    }

                    bArrangementArray.push(bArrangement);
                }
            }

            // 部屋単位情報作成
            const element = rdStore.bBasicInformation.bRoomUnit[index];

            let tempBookDeadline = '';
            if (!StringControl.dateStringIsEmpty(element.tempBookDeadline)) {
                tempBookDeadline = format(element.tempBookDeadline!, 'yyyy/MM/dd');
            }
            

            const bRoomUnit: IBRoomUnit = {
                id: element.id,
                status: element.status,	//	予約ステータス
                checkInDate: format(element.checkInDate, 'yyyy/MM/dd'),	//	チェックイン日
                checkInTime: element.checkInTime,	//	チェックイン時間
                checkOutDate: format(element.checkOutDate, 'yyyy/MM/dd'),	//	チェックアウト日
                checkOutTime: element.checkOutTime,	//	チェックアウト時間
                nights: element.nights,	//	宿泊日数
                // キャンセル理由
                memo: reason,	//	予約メモ情報

                bRoomInformation: bRoomInformationArray,	// 部屋情報
                bRoomInformationForDel: element.bRoomInformationForDel,

                bGuestInformation: bGuestInformationArray,   // お客様情報
                bGuestInformationForDel: element.bGuestInformationForDel,

                bOrder: orderArray,    // 利用明細情報
                bOrdersForDel: element.bOrdersForDel,

                bPayment: paymentArray,
                bPaymentsForDel: element.bPaymentsForDel,

                tempBookDeadline:  tempBookDeadline, // 仮予約期限
                isRepresentativeRoom: element.isRepresentativeRoom,

                otaxNotCounted: element.otaxNotCounted,
                bFreeMemo: bFreeMemoArray, // フリーメモ
                bFreeMemoForDel: element.bFreeMemoForDel,
                
                bArrangement: bArrangementArray,
                bArrangementForDel: element.bArrangementForDel,
            }

            bRoomUnitArray.push(bRoomUnit);

        }

        // edit BasicInformation Object
        const bBasicInformation: IBBasicInformation = {
            id: rdStore.bBasicInformation.id,
            status: rdStore.bBasicInformation.status,	//	予約ステータス
            bookingNo: rdStore.bBasicInformation.bookingNo,	//	予約番号
            guestOrGroupNameSingleByte: rdStore.bBasicInformation.guestOrGroupNameSingleByte,	//	団体名または代表者氏名(半角)
            guestOrGroupNameKanjiName: rdStore.bBasicInformation.guestOrGroupNameKanjiName,	//	団体名または代表者氏名 漢字
            guestOrGroupNamePhoneNumber: rdStore.bBasicInformation.guestOrGroupNamePhoneNumber,	//	団体名または代表者電話番号
            guestOrGroupNamePhoneNumberType: rdStore.bBasicInformation.guestOrGroupNamePhoneNumberType, // 団体名または代表者電話番号区分
            // guestOrGroupNamePhoneNumber2: rdStore.bBasicInformation.guestOrGroupNamePhoneNumber2,	//	団体名または代表者電話番号２
            // guestOrGroupNamePhoneNumber2Type: rdStore.bBasicInformation.guestOrGroupNamePhoneNumber2Type, // 団体名または代表者電話番号2区分
            bRoomUnit: bRoomUnitArray,	// 部屋単位情報
            payIndivisualOrBulk: rdStore.bBasicInformation.payIndivisualOrBulk
        };


        const sendData: IRegistrationCreateRequest = {
            groupId: targetStore.groupId,	//	グループID
            companyId: targetStore.companyId!,	//	会社ID
            storeId: targetStore.storeId!,	//	店舗ID
            submitUserEmail: submitAuthInfo.email,
            inputBy: submitAuthInfo.inputBy,	//	入力者ID
            password: submitAuthInfo.password,
            machine_no: authStore.machineId!,    // 端末ID
            bBasicInformation: bBasicInformation	// 基本情報
        };

        console.log("cancel registration req: " + sendData);
        console.log(sendData)

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/edit", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);
                    if (res.returnCode === 200) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    console.log("cancelRegistration Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });

    }

    // For registration Check In
    checkInRegistration(
        targetStore: IUserTragetStoreInfo,
        inputBy: string
    ) {

        const rdStore = useRegistrationStore();
        const authStore = useAuthStore();
        const checkInDialogStore = useCheckInDialogStore();

        // 部屋単位情報オブジェクト 作成
        const bRoomUnitArray: ICheckInBRoomUnit[] = Array();

        for (let index = 0; index < rdStore.bBasicInformation.bRoomUnit.length; index++) {

            // 部屋単位情報作成
            const element = rdStore.getRoomUnit;

            const bRoomUnit: ICheckInBRoomUnit = {
                id: element.id!
            }

            bRoomUnitArray.push(bRoomUnit);

        }

        const bBasicInformation: ICheckInBBasicInformation = {
            id: rdStore.bBasicInformation.id!,
            // status: "BOOK",	//	予約ステータス
            // bookingNo: rdStore.bBasicInformation.bookingNo,	//	予約番号
            // guestOrGroupNameSingleByte: rdStore.bBasicInformation.guestOrGroupNameSingleByte,	//	団体名または代表者氏名(半角)
            // guestOrGroupNameKanjiName: rdStore.bBasicInformation.guestOrGroupNameKanjiName,	//	団体名または代表者氏名 漢字
            // guestOrGroupNamePhoneNumber: rdStore.bBasicInformation.guestOrGroupNamePhoneNumber,	//	団体名または代表者電話番号
            // guestOrGroupNamePhoneNumber2: rdStore.bBasicInformation.guestOrGroupNamePhoneNumber2,	//	団体名または代表者電話番号２
            bRoomUnit: bRoomUnitArray,	// 部屋単位情報
        };

        // Create payment param
        const billPaymentArray: IBillPayment[] = Array();

        for (let index = 0; index < checkInDialogStore.billingComponent!.billingPayments!.length; index++) {
            const billingPayment = checkInDialogStore.billingComponent?.billingPayments![index];

            if (billingPayment?.paymentAccountId === 0) {
                continue;
            }

            const billPayment: IBillPayment = {
                bBasicInformationId: rdStore.bBasicInformation.id!,
                bRoomUnitId: checkInDialogStore.billingComponent?.bRoomUnitId!,	//	部屋単位情報ID
                paymentMethodId: billingPayment?.paymentMethodId!,	// 金種ID
                paymentMethodName: billingPayment?.paymentMethodName!,	// 金種名
                paymentAccountId: billingPayment?.paymentAccountId!,	// 入金科目ID
                paymentAccountName: billingPayment?.paymentAccountName!,	// 入金科目名
                paymentManageReceivable: billingPayment?.paymentManageReceivable!, // 売掛金管理
                paymentBookkeepingCd: billingPayment?.paymentBookkeepingCd!, // 会計科目コード
                paymentAmount: billingPayment?.paymentAmount!,	// 支払金額
            }

            billPaymentArray.push(billPayment);
        }

        // Create payment param
        const billPlanArray: IBillPlan[] = Array();

        for (let index = 0; index < checkInDialogStore.billingComponent!.billingPlans!.length; index++) {
            const billingPlan = checkInDialogStore.billingComponent?.billingPlans![index];

            // Create payment param
            const billDetailsArray: IBillDetail[] = Array();

            for (let j = 0; j < billingPlan?.billingDetails.length!; j++) {
                const billingDetail = billingPlan?.billingDetails[j]!;
                billDetailsArray.push(this.setBillingDetail(billingDetail));
            }

            const billPlan: IBillPlan = {
                bBasicInformationId: rdStore.bBasicInformation.id!, //	基本宿泊情報ID
                bRoomUnitId: checkInDialogStore.billingComponent?.bRoomUnitId!,	//	部屋単位情報ID
                bRoomInformationId: billingPlan?.bRoomUnitId!,	//	部屋情報ID
                bGuestInformationId: billingPlan?.bGuestInformationId,	// ゲスト情報ID

                bOrderId: 0,	//	利用明細情報ID
                billId: 0,  // 領収ID
                billRoomId: billingPlan?.billRoomId!,	// 伝票 部屋ID
                billRoomNo: billingPlan?.billRoomNo!,	// 伝票 部屋NO

                planId: billingPlan?.planId!,	// 企画(パッケージ)id
                planName: billingPlan?.planName!,	// 企画(パッケージ)名
                planCode: billingPlan?.planCode!,	// 企画(パッケージ)コード

                useDate: billingPlan?.useDate!,	// 利用年月日
                useRoomId: billingPlan?.useRoomId!,	// 利用部屋
                useRoomNo: billingPlan?.useRoomNo!,	// 利用部屋名

                unitPrice: billingPlan?.unitPrice!,	// 単価
                quantity: billingPlan?.quantity!,	// 数量
                amount: billingPlan?.amount!,	// 合計金額
                taxation: billingPlan?.taxation!, // 課税・非課税 :YES: 課税 NO: 非課税
                taxRateType: billingPlan?.taxRateType!, // 消費税区分 : CON: 標準 REDUCED: 軽減税率
                taxRate: billingPlan?.taxRate!,	// 消費税率
                taxAmount: billingPlan?.taxAmount!,	// 消費税額
                totalAmount: billingPlan?.totalAmount!,	// 合計金額

                perPaxRate: 0,	//	大人単価（一人料金)
                perChildA70Rate: 0,	//	子供A単価（一人料金)
                perChildB50Rate: 0,	//	子供B単価（一人料金)
                perChildC30Rate: 0,	//	子供C単価（一人料金)
                perChildDrate: 0,	//	子供D単価（一人料金)
                perChildErate: 0,	//	子供E単価（一人料金)
                perChildFrate: 0,	//	子供F単価（一人料金)
                perChildOtherRate: 0,	//	子供その他単価（一人料金)

                perRoomPaxCount: 0,	//	1室利用人数
                roomPaxMaleCount: 0,	//	大人人員(男性)
                roomPaxFemaleCount: 0,	//	大人人員(女性)
                roomChildA70Count: 0,	//	子供A人数
                roomChildB50Count: 0,	//	子供B人数
                roomChildC30Count: 0,	//	子供C人数
                roomChildDnoneCount: 0,	//	子供D人数
                roomChildEcount: 0,	//	子供E人数
                roomChildFcount: 0,	//	子供F人数
                roomChildOtherCount: 0,	//	子供その他人数

                billDetails: billDetailsArray,
            }

            billPlanArray.push(billPlan);
        }

        // Bill
        const bill: IBill = {
            bBasicInformationId: rdStore.bBasicInformation.id!,
            bRoomUnitId: checkInDialogStore.billingComponent?.bRoomUnitId!,	//	部屋単位情報ID
            bGuestInformationId: 0,	// ゲスト情報ID
            // receiptName: checkInDialogStore.billingComponent?.receiptName, 	// 領収署名
            // checkInDate: checkInDialogStore.billingComponent?.checkInDate,	// チェックイン日
            // checkOutDate: checkInDialogStore.billingComponent?.checkInDate,	// チェックアウト日
            // roomId: checkInDialogStore.billingComponent?.roomId,	// 部屋ID
            // roomName: checkInDialogStore.billingComponent?.roomName,	// 部屋名
            // roomNameE: checkInDialogStore.billingComponent?.roomNameE,	// 部屋名 英語

            receiptName: '', 	// 領収署名
            checkInDate: '',	// チェックイン日
            checkOutDate: '',	// チェックアウト日
            roomId: 0,	// 部屋ID
            roomName: '',	// 部屋名
            roomNameE: '',	// 部屋名 英語

            // billPayments: billPaymentArray,
            billPlans: billPlanArray,
            billDetails: []
        }

        const sendData: IRegistrationCheckInRequest = {
            groupId: targetStore.groupId,	//	グループID
            companyId: targetStore.companyId!,	//	会社ID
            storeId: targetStore.storeId!,	//	店舗ID
            inputBy: inputBy,	//	入力者ID
            bBasicInformation: bBasicInformation,	// 基本情報
            bill: bill,
            billPayments: billPaymentArray,
            machine_no: authStore.machineId!,
        };

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/checkin", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(data.returnCode);
                    resolve(data);
                }).catch(response => {
                    console.log("checkInRegistration Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    private setBillingDetail = (order: IBillingDetailComponent) => {

        const detail: IBillDetail = {
            bBasicInformationId: order.bBasicInformationId,
            bRoomUnitId: order.bRoomUnitId,	//	部屋単位情報ID
            bRoomInformationId: order.bRoomInformationId,	//	部屋情報ID
            bGuestInformationId: order.bGuestInformationId,	// ゲスト情報ID
            bOrderId: order.bOrderId,	//	利用明細情報ID

            billId: order.billId,	//	精算情報ID
            billPlanId: order.billPlanId,	//	精算プラン情報ID

            billRoomId: order.billRoomId,	// 伝票 部屋ID
            billRoomNo: order.billRoomNo,	// 伝票 部屋NO

            useDate: order.useDate,	// 利用年月日
            useRoomId: order.useRoomId,	// 利用部屋
            useRoomNo: order.useRoomNo,	// 利用部屋名
            itemId: order.itemId,	// 商品コード
            itemName: order.itemName,	// 商品名
            itemNameE: order.itemNameE,	// 商品名（英語）
            itemNameCd: order.itemNameCd,	//	商品コード
            itemBookkeepingCd: order.itemBookkeepingCd,	//	商品コード

            unitPrice: order.unitPrice,	// 単価
            quantity: order.quantity,	// 数量
            amount: order.amount,	// 合計金額
            taxation: order.taxation, // 課税・非課税 :YES: 課税 NO: 非課税
            taxRateType: order.taxRateType, // 消費税区分 : CON: 標準 REDUCED: 軽減税率
            taxRate: order.taxRate,	// 消費税率
            taxAmount: order.taxAmount,	// 消費税額
            totalAmount: order.totalAmount,	// 合計金額

            perPaxRate: order.perPaxRate,	//	大人単価（一人料金)
            perChildA70Rate: order.perChildA70Rate,	//	子供A単価（一人料金)
            perChildB50Rate: order.perChildB50Rate,	//	子供B単価（一人料金)
            perChildC30Rate: order.perChildC30Rate,	//	子供C単価（一人料金)
            perChildDrate: order.perChildDrate,	//	子供D単価（一人料金)
            perChildErate: order.perChildErate,	//	子供E単価（一人料金)
            perChildFrate: order.perChildFrate,	//	子供F単価（一人料金)
            perChildOtherRate: order.perChildOtherRate,	//	子供その他単価（一人料金)

            perRoomPaxCount: order.perRoomPaxCount,	//	1室利用人数
            roomPaxMaleCount: order.roomPaxMaleCount,	//	大人人員(男性)
            roomPaxFemaleCount: order.roomPaxFemaleCount,	//	大人人員(女性)
            roomChildA70Count: order.roomChildA70Count,	//	子供A人数
            roomChildB50Count: order.roomChildB50Count,	//	子供B人数
            roomChildC30Count: order.roomChildC30Count,	//	子供C人数
            roomChildDnoneCount: order.roomChildDnoneCount,	//	子供D人数
            roomChildEcount: order.roomChildEcount,	//	子供E人数
            roomChildFcount: order.roomChildFcount,	//	子供F人数
            roomChildOtherCount: order.roomChildOtherCount,	//	子供その他人数

        };
        return detail;
    }

    /**
     * チェックアウト実行
     * @param targetStore 
     * @param inputBy 
     * @returns 
     */
    roomUnitCheckOut(
        submitAuthInfo: ISubmitAuthComponent
    ) {
        const rdStore = useRegistrationStore();
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        // 部屋単位情報オブジェクト 作成
        const bRoomUnitArray: ICheckInBRoomUnit[] = Array();

        const bRoomUnit: ICheckInBRoomUnit = {
            id: rdStore.getRoomUnit.id!
        }

        bRoomUnitArray.push(bRoomUnit);

        // for (let index = 0; index < rdStore.bBasicInformation.bRoomUnit.length; index++) {

        //     // 部屋単位情報作成
        //     const element = rdStore.bBasicInformation.bRoomUnit[index];

        //     const bRoomUnit: ICheckInBRoomUnit = {
        //         id: element.id!
        //     }

        //     bRoomUnitArray.push(bRoomUnit);

        // }

        const bBasicInformation: ICheckInBBasicInformation = {
            id: rdStore.bBasicInformation.id!,
            bRoomUnit: bRoomUnitArray,	// 部屋単位情報
        };

        const sendData: IRegistrationCheckInRequest = {
            groupId: groupId,	//	グループID
            companyId: companyId!,	//	会社ID
            storeId: storeId!,	//	店舗ID
            submitUserEmail: submitAuthInfo.email,
            inputBy: submitAuthInfo.inputBy,	//	入力者ID
            password: submitAuthInfo.password,
            machine_no: authStore.machineId!,    // 端末ID
            bBasicInformation: bBasicInformation	// 基本情報
        };

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/roomUnitCheckout", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);
                    if (res.returnCode === 200) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    console.log("room unit Checkout Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }
    
    /**
     * 一括チェックアウト実行
     * @param targetStore 
     * @param inputBy 
     * @returns 
     */
    multipRoomUnitCheckOut(
        submitAuthInfo: ISubmitAuthComponent,
        roomUnitIds: number[]
    ) {
        const rdStore = useRegistrationStore();
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        // 部屋単位情報オブジェクト 作成
        const bRoomUnitArray: ICheckInBRoomUnit[] = Array();

        for (let index = 0; index < roomUnitIds.length; index++) {
            const item = roomUnitIds[index];
            
            const roomUnit = {
                id : item
            } as ICheckInBRoomUnit;

            bRoomUnitArray.push(roomUnit);
        }

        const bBasicInformation: ICheckInBBasicInformation = {
            id: rdStore.bBasicInformation.id!,
            bRoomUnit: bRoomUnitArray,	// 部屋単位情報
        };

        const sendData: IRegistrationCheckInRequest = {
            groupId: groupId,	//	グループID
            companyId: companyId!,	//	会社ID
            storeId: storeId!,	//	店舗ID
            submitUserEmail: submitAuthInfo.email,
            inputBy: submitAuthInfo.inputBy,	//	入力者ID
            password: submitAuthInfo.password,
            machine_no: authStore.machineId!,    // 端末ID
            bBasicInformation: bBasicInformation	// 基本情報
        };

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/roomUnitCheckout", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);
                    if (res.returnCode === 200) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    console.log("room unit Checkout Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }



    // For registration Check In
    checkOutRegistration(
        targetStore: IUserTragetStoreInfo,
        inputBy: string
    ) {

        const rdStore = useRegistrationStore();
        const authStore = useAuthStore();

        // 部屋単位情報オブジェクト 作成
        const bRoomUnitArray: ICheckInBRoomUnit[] = Array();

        for (let index = 0; index < rdStore.bBasicInformation.bRoomUnit.length; index++) {

            // 部屋単位情報作成
            const element = rdStore.bBasicInformation.bRoomUnit[index];

            const bRoomUnit: ICheckInBRoomUnit = {
                id: element.id!
            }

            bRoomUnitArray.push(bRoomUnit);

        }

        const bBasicInformation: ICheckInBBasicInformation = {
            id: rdStore.bBasicInformation.id!,
            // status: "BOOK",	//	予約ステータス
            // bookingNo: rdStore.bBasicInformation.bookingNo,	//	予約番号
            // guestOrGroupNameSingleByte: rdStore.bBasicInformation.guestOrGroupNameSingleByte,	//	団体名または代表者氏名(半角)
            // guestOrGroupNameKanjiName: rdStore.bBasicInformation.guestOrGroupNameKanjiName,	//	団体名または代表者氏名 漢字
            // guestOrGroupNamePhoneNumber: rdStore.bBasicInformation.guestOrGroupNamePhoneNumber,	//	団体名または代表者電話番号
            // guestOrGroupNamePhoneNumber2: rdStore.bBasicInformation.guestOrGroupNamePhoneNumber2,	//	団体名または代表者電話番号２
            bRoomUnit: bRoomUnitArray,	// 部屋単位情報
        };

        const sendData: IRegistrationCheckInRequest = {
            groupId: targetStore.groupId,	//	グループID
            companyId: targetStore.companyId!,	//	会社ID
            storeId: targetStore.storeId!,	//	店舗ID
            inputBy: inputBy,	//	入力者ID
            machine_no: authStore.machineId!,    // 端末ID
            bBasicInformation: bBasicInformation	// 基本情報
        };

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/checkout", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(data.returnCode);
                    resolve(data);
                }).catch(response => {
                    console.log("checkOutRegistration Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    /**
     * 利用明細タブ切り替え時、利用明細取得
     * @param targetStore 
     * @returns 
     */
    getOrdersAndPayments(
        targetStore: IUserTragetStoreInfo,
    )
    {
        const rdStore = useRegistrationStore();

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id,
            pay_indivisual_or_bulk: rdStore.bBasicInformation.payIndivisualOrBulk,
            is_representative_room: rdStore.getRoomUnit.isRepresentativeRoom
        };

        return new Promise<IUseDetailsComponent>((resolve, reject) => {
            try {
                http.post("/api/registration/getUseDetail", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IRegistrationOrdersAndPaymentsResponse;
                    console.log(data.returnCode);

                    if (data.returnCode === 200) {
                        const useDetails = data.useDetails as IUseDetailsComponent;

                        for (let index = 0; index < useDetails.bOrders.length; index++) {
                            useDetails.bOrders[index].rowIndex = index;
                        }

                        for (let index = 0; index < useDetails.bPayments.length; index++) {
                            useDetails.bPayments[index].rowIndex = index;
                        }
                        resolve(useDetails);
                    } else {
                        reject(data);
                    }
                }).catch(response => {
                    console.log("getOperationHistories Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }

        })
    }

    /**
     * 利用明細タブ切り替え時、利用明細取得
     * @param targetStore 
     * @returns 
     */
    public getRoomInformation(
        targetStore: IUserTragetStoreInfo,
        bBasicInformationId: number,
        bRoomUnitId: number,
        bRoomInformationId: number
    ){
        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            b_basic_information_id: bBasicInformationId,
            b_room_unit_id: bRoomUnitId,
            b_room_information_id: bRoomInformationId
        };

        return new Promise<IRoomInformationComponent>((resolve, reject) => {
            try {
                http.post("/api/registration/getRoomInformation", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res = CConvertor.toCamelCaseObject(response.data) as IRoomInformationResponse;
                    if (res.returnCode === 200) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    console.log("getOperationHistories Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }

        })
    }

    public getGuestInformations (
        targetStore: IUserTragetStoreInfo,
    )
    {
        const rdStore = useRegistrationStore();

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id
        };

        return new Promise<IGuestInformationComponent[]>((resolve, reject) => {
            try {
                http.post("/api/registration/getGuestInfo", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IRegistrationGuestInfosResponse;
                    console.log(data.returnCode);

                    if (data.returnCode === 200) {
                        const guestInfo = data.guestInfo as IGuestInformationComponent[];
                        resolve(guestInfo);
                    } else {
                        reject(data);
                    }
                }).catch(response => {
                    console.log("getGuestInformations Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }

        })

    }

    /**
     * 履歴タブ切り替え時、履歴取得
     * @param targetStore
     * @returns IOperationHistoryComponent[]
     */
    getOperationHistories(
        targetStore: IUserTragetStoreInfo,
    )
    {
        const rdStore = useRegistrationStore();

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id
        };

        return new Promise<IOperationHistoryComponent[]>((resolve, reject) => {
            try {
                http.post("/api/registration/getOperationHistory", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IRegistrationOperationHistoryResponse;
                    console.log(data.returnCode);

                    if (data.returnCode === 200) {
                        const operationHistories = data.operationHistories as IOperationHistoryComponent[]
                        resolve(operationHistories);
                    } else {
                        reject(data);
                    }
                }).catch(response => {
                    console.log("getOperationHistories Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }

        })

    }

    /**
     * 利用明細登録
     * @param submitAuthInfo 認証情報
     * @param payments 入金
     * @returns 
     */
    savePayments(
        submitAuthInfo: ISubmitAuthComponent,
        payments: IBPaymentComponent[]
    ){
        const rdStore = useRegistrationStore();
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        const reqPayments =  JSON.parse(JSON.stringify(payments));

        const sendData: { [key: string]: any; } = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id,
            b_payments: reqPayments,
            submitUserEmail: submitAuthInfo.email,
            input_by: submitAuthInfo.inputBy,
            password: submitAuthInfo.password,
            machine_no: authStore.machineId
        };

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/savePaymentsByRecipt", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);
                    if (res.returnCode === 200) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    console.log("savePayments Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });

    }

    // /**
    //  * 利用明細登録
    //  * @param targetStore 
    //  * @returns 
    //  */
    // saveOrdersAndPayments(
    //     targetStore: IUserTragetStoreInfo,
    //     submitAuthInfo: ISubmitAuthComponent,
    //     orders: IOrderComponent[],
    //     payments: IBPaymentComponent[]
    // )
    // {
    //     const rdStore = useRegistrationStore();
    //     const authStore = useAuthStore();
    //     const roomUnit = rdStore.getRoomUnit;

    //     for (let index = 0; index < orders.length; index++) {
    //         const order = orders[index];
    //         if (order.id === 0) {
    //             // Set roomInfomationId to new order record.
    //             // order.bBasicInformationId = rdStore.bBasicInformation.id!;
    //             // order.bRoomUnitId = roomUnit.id!;
    //             const roomInfo = roomUnit.bRoomInformation.find((data) => data.roomDate === order.useDate);

    //             if (roomInfo !== undefined) {
    //                 order.bRoomInformationId = roomInfo?.id!;
    //             }
    //         }
    //     }

    //     const reqOrders = JSON.parse(JSON.stringify(orders));

    //     const reqPayments =  JSON.parse(JSON.stringify(payments));

    //     const sendData: { [key: string]: any; } = {
    //         group_id: targetStore.groupId,
    //         company_id: targetStore.companyId,
    //         store_id: targetStore.storeId,
    //         b_basic_information_id: rdStore.bBasicInformation.id,
    //         b_room_unit_id: rdStore.getRoomUnit.id,
    //         b_orders: reqOrders,
    //         b_payments: reqPayments,
    //         submitUserEmail: submitAuthInfo.email,
    //         input_by: submitAuthInfo.inputBy,
    //         password: submitAuthInfo.password,
    //         machine_no: authStore.machineId
    //     };

    //     return new Promise((resolve, reject) => {
    //         try {
    //             http.post("/api/registration/saveOrdersAndPayments", CConvertor.toSnakeCaseObject(sendData)
    //             ).then(response => {
    //                 const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
    //                 console.log(res.returnCode);
    //                 if (res.returnCode === 200) {
    //                     resolve(res.data);
    //                 } else {
    //                     reject(res);
    //                 }
    //             }).catch(response => {
    //                 console.log("saveOrdersAndPayments Error");
    //                 console.log(response);
    //                 reject(response);
    //             });
    //         } catch(e) {
    //             console.log(e+"処理失敗");
    //             reject(e);
    //         }
    //     });

    // }

    /**
     * 途中精算
     */
    saveMidFlowPayment(
        submitAuthInfo: ISubmitAuthComponent,
        checkoutFlag: string
    ){
        const rdStore = useRegistrationStore();
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        const registrationSettlementDialogStore = useRegistrationSettlementDialogStore();
        const preBilling = registrationSettlementDialogStore.preBillComponent;

        const preBillingReq = JSON.parse(JSON.stringify(preBilling));

        const sendData: { [key: string]: any; } = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id,
            settlement_bills: preBillingReq,
            submitUserEmail: submitAuthInfo.email,
            input_by: submitAuthInfo.inputBy,
            password: submitAuthInfo.password,
            checkoutFlag,
            machine_no: authStore.machineId
        };

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/saveMidFlowPayment", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);
                    if (res.returnCode === 200) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    console.log("saveMidFlowPayment Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });

    }

    /**
     * 精算取消
     */
    cancelPayment(
        submitAuthInfo: ISubmitAuthComponent,
        roomInformationIds: number[],
        orderIds: number[],
        paymentIds: number[],
        bBasicInformationId: number,
        bRoomUnitId: number
    )
    {
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        const sendData: { [key: string]: any; } = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            submitUserEmail: submitAuthInfo.email,
            input_by: submitAuthInfo.inputBy,
            password: submitAuthInfo.password,
            room_information_ids: roomInformationIds,
            order_ids: orderIds,
            payment_ids: paymentIds,
            machine_no: authStore.machineId,
            b_basic_information_id: bBasicInformationId,
            b_room_unit_id: bRoomUnitId
        };

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/cancelPayment", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);
                    if (res.returnCode === 200) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    console.log("cancelPayment Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });

    }

    getBillsDropDownForUserDetail () {

        const rdStore = useRegistrationStore();
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        const sendData: { [key: string]: any; } = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id
        };

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/getBillsDropDownForUserDetail", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);
                    if (res.returnCode === 200) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    console.log("getBillsDropDownForUserDetail Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    getDropdownCountry() {
        const sendData: { [key: string]: any; } = {};
        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/getDropdownCountry", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as ICountryResponse;
                    if (data.returnCode === 200) {
                        const country = data.data as ICountryComponent[];
                        resolve(country);
                    } else {
                        reject(response);
                    }
                }).catch(response => {
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    getDropdownPrefectures() {
        const sendData: { [key: string]: any; } = {};
        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/getDropdownPrefectures", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as ICountryResponse;
                    if (data.returnCode === 200) {
                        const prefectures = data.data as ICountryComponent[];
                        resolve(prefectures);
                    } else {
                        reject(response);
                    }
                }).catch(response => {
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    /**
     * 
     * @param condition 
     * @param targetStore 
     * @returns 
     */
    public getCustomerListByCondition (
        condition: string, 
        targetStore: IUserTragetStoreInfo
    ) {
        const rdStore = useRegistrationStore();

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            b_basic_information_id: rdStore.getRoomUnit.bGuestInformation[rdStore.guestInfoIndex].id,
            b_room_unit_id: rdStore.getRoomUnit.id,
            condition: condition,
            guest_phone_number: rdStore.getRoomUnit.bGuestInformation[rdStore.guestInfoIndex].guestPhoneNumber,
            guest_name_single_byte: rdStore.getRoomUnit.bGuestInformation[rdStore.guestInfoIndex].guestNameSingleByte
        };
        const selected_customer_ids: any[] = [];
        rdStore.getRoomUnit.bGuestInformation.forEach(item => {
            selected_customer_ids.push(item.customerId);
        })
        sendData.selected_customer_ids = selected_customer_ids;
        return new Promise<ICustomerComponent[]>((resolve, reject) => {
            try {
                http.post("/api/registration/getCustomerListByCondition", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IRegistrationCustomersResponse;
                    console.log(data.returnCode);

                    if (data.returnCode === 200) {
                        const customers = data.customers as ICustomerComponent[];
                        resolve(customers);
                    } else {
                        reject(data);
                    }
                }).catch(response => {
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }

        })

    }

    public getGroupCustomerListByCondition(targetStore: IUserTragetStoreInfo) {
        const rdStore = useRegistrationStore();

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            group_name_single_byte: rdStore.bBasicInformation.groupNameSingleByte
        };

        return new Promise<ICustomerGroupComponent[]>((resolve, reject) => {
            try {
                http.post("/api/registration/getGroupCustomerListByCondition", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IRegistrationGroupCustomersResponse;
                    console.log(data.returnCode);

                    if (data.returnCode === 200) {
                        const customers = data.customers as ICustomerGroupComponent[];
                        resolve(customers);
                    } else {
                        reject(data);
                    }
                }).catch(response => {
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }

        })

    }

    public getGuestInfoByNameAndPhone (
        targetStore: IUserTragetStoreInfo,
    )
    {
        const rdStore = useRegistrationStore();

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            b_basic_information_id: rdStore.getRoomUnit.bGuestInformation[rdStore.guestInfoIndex].id,
            b_room_unit_id: rdStore.getRoomUnit.id,
            guest_phone_number: rdStore.getRoomUnit.bGuestInformation[rdStore.guestInfoIndex].guestPhoneNumber,
            guest_name_single_byte: rdStore.getRoomUnit.bGuestInformation[rdStore.guestInfoIndex].guestNameSingleByte
        };

        return new Promise<IGuestInformationComponent>((resolve, reject) => {
            try {
                http.post("/api/registration/getGuestInfoByNameAndPhone", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IRegistrationGuestInfoResponse;
                    console.log(data.returnCode);

                    if (data.returnCode === 200) {
                        const guestInfo = data.guestInfo as IGuestInformationComponent;
                        resolve(guestInfo);
                    } else {
                        reject(data);
                    }
                }).catch(response => {
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }

        })

    }

    public getPostalAddress(zipcode: string) {
        const sendData: { [key: string]: any; } = {
            zipcode: zipcode
        };
        return new Promise<IPostalAddressComponent[]>((resolve, reject) => {
            try {
                http.post("/api/registration/getPostalAddress", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IPostalAddressResponse;
                    console.log(data.returnCode);

                    if (data.returnCode === 200) {
                        const address = data.address as IPostalAddressComponent[];
                        resolve(address);
                    } else {
                        reject(data);
                    }
                }).catch(response => {
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        })
    }

    public getAccountingItems(targetStore: IUserTragetStoreInfo, accountingGroup: string) {
        console.log("RegistrationService getAccountingItems " + accountingGroup + " START");

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            accounting_group: accountingGroup
        };

        console.log(JSON.stringify(sendData));

        return new Promise<IAccountingItemComponent[]>((resolve, reject) => {
            try {
                http.post("/api/registration/getAccountingItems", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IRegistrationAccountingItemsResponse;
                    console.log(res.returnCode);

                    if (res.returnCode === 200) {
                        const accountingItems = res.data as IAccountingItemComponent[];
                        resolve(accountingItems);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        })
    }

    public getBillingAddressItems(targetStore: IUserTragetStoreInfo) {
        console.log("RegistrationService getBillingAddressItems START");
        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId
        };

        console.log(JSON.stringify(sendData));

        return new Promise<IBillingAddressComponent[]>((resolve, reject) => {
            try {
                http.post("/api/registration/getBillingAddressItems", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IRegistrationBillingAddressItemsResponse;
                    console.log(res.returnCode);

                    if (res.returnCode === 200) {
                        const accountingItems = res.data as IBillingAddressComponent[];
                        resolve(accountingItems);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        })
    }
    
    public previewDetailsPdf(targetStore: IUserTragetStoreInfo) {

        const rdStore = useRegistrationStore();
        const registrationReceiptStore = useRegistrationReceiptDetailDialogStore();
        const authStore = useAuthStore();

        const receipt = registrationReceiptStore.receipt[registrationReceiptStore.receiptIndex];

        const reqReceipt = JSON.parse(JSON.stringify(receipt));

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id,
            receipt: reqReceipt,
            receipt_id: receipt.id!,
            machine_no: authStore.machineId
        };
        
        const config: AxiosRequestConfig = {
            responseType: 'blob'
        }
        
        return new Promise((resolve, reject) => {
            try {
                http.post<BlobPart>("/api/registration/previewDetailsPdf", CConvertor.toSnakeCaseObject(sendData))
                    .then(response => {
                        console.log(response);
                        resolve(response.data);
                    })
            } catch(e) {
                reject(e);
            }
        })
    }


    public updateReceiptStatus(submitAuthInfo: ISubmitAuthComponent) {
        const rdStore = useRegistrationStore();
        const authStore = useAuthStore();

        const { groupId, companyId, storeId } = authStore.targetStore!;

        const sendData: { [key: string]: any; } = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id,
            machine_no: authStore.machineId,
            submitUserEmail: submitAuthInfo.email,
            inputBy: submitAuthInfo.inputBy,	//	入力者ID
            password: submitAuthInfo.password,
        };

        return new Promise<IInternalResponse>((resolve, reject) => {
            try {
                http.post("/api/receipt/updateReceiptStatus", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const res =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(res.returnCode);

                    if (res.returnCode === 200) {
                        resolve(res.data);
                    } else {
                        reject(res);
                    }
                }).catch(response => {
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        })
    }

    public generateDetailsPdf(targetStore: IUserTragetStoreInfo, receiptId: Number, details: any) {

        const rdStore = useRegistrationStore();
        const authStore = useAuthStore();

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id,
            receipt_id: receiptId,
            details,
            machine_no: authStore.machineId
        };
        const config: AxiosRequestConfig = {
            responseType: 'blob'
        }
        console.log(sendData)
        return new Promise((resolve, reject) => {
            try {
                http.post<BlobPart>("/api/registration/previewDetailsPdf", sendData)
                    .then(response => {
                        console.log(response);
                        resolve(response.data);
                    })
            } catch(e) {
                reject(e);
            }
        })
    }

    public getReceiptHistories(targetStore: IUserTragetStoreInfo) {
        const rdStore = useRegistrationStore();

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id
        };

        return new Promise<IReceiptHistoryComponent[]>((resolve, reject) => {
                try {
                    http.post("/api/receipt/getReceiptHistory", CConvertor.toSnakeCaseObject(sendData)
                    ).then(response => {
                        const data =  CConvertor.toCamelCaseObject(response.data) as IRegistrationReceiptHistoryResponse;
                        console.log(data.returnCode);

                        if (data.returnCode === 200) {
                            const receiptHistories = data.data as IReceiptHistoryComponent[]

                            resolve(receiptHistories);
                        } else {
                            reject(data);
                        }
                    }).catch(response => {
                        reject(response);
                    });
                } catch(e) {
                    console.log(e+"処理失敗");
                    reject(e);
                }

            })
    }

    getReceiptDetail(targetStore: IUserTragetStoreInfo, receiptId: number) {
        const rdStore = useRegistrationStore();

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            receipt_id: receiptId
        };

        return new Promise<IReceiptHistoryComponent>((resolve, reject) => {
                try {
                    http.post("/api/receipt/getReceiptDetail", CConvertor.toSnakeCaseObject(sendData)
                    ).then(response => {
                        const data =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        console.log(data.returnCode);

                        if (data.returnCode === 200) {
                            const receiptHistories = data.data as IReceiptHistoryComponent

                            resolve(receiptHistories);
                        } else {
                            reject(data);
                        }
                    }).catch(response => {
                        reject(response);
                    });
                } catch(e) {
                    console.log(e+"処理失敗");
                    reject(e);
                }

            })
    }

    getOperationHistoryList(targetStore: IUserTragetStoreInfo) {
        const rdStore = useRegistrationStore();

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id
        };

        return new Promise<IOperationHistoryComponent[]>((resolve, reject) => {
            try {
                http.post("/api/operation/history/getOperationHistories", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IRegistrationOperationHistoryResponse;
                    console.log(data.returnCode);

                    if (data.returnCode === 200) {
                        const operationHistories = data.data as IOperationHistoryComponent[]
                        resolve(operationHistories);
                    } else {
                        reject(data);
                    }
                }).catch(response => {
                    console.log("getOperationHistories Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }

        })

    }

    /**
     * 領収書発行
     */
    requestIssueReceipt(targetStore: IUserTragetStoreInfo){
        const rdStore = useRegistrationStore();
        const authStore = useAuthStore();

        const registrationReceiptDetailDialogStore = useRegistrationReceiptDetailDialogStore();

        const receipt = registrationReceiptDetailDialogStore.receipt;

        const reqReceipt = JSON.parse(JSON.stringify(receipt));


        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id,
            receipt: reqReceipt,
            previewReceiptId: registrationReceiptDetailDialogStore.previewReceiptId,
            receiptType: registrationReceiptDetailDialogStore.receiptType,
            delete_page_id: registrationReceiptDetailDialogStore.deletePages,
            machine_no: authStore.machineId
        };

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/issueReceipt", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(data.returnCode);
                    resolve(data);
                }).catch(response => {
                    console.log("issueReceipt Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });

    }

    /**
     * 編集中で未発行の領収書を取得
     */
    getReceiptHistoryAndDetail(){
        const rdStore = useRegistrationStore();

        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        const sendData: { [key: string]: any; } = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id
        };

        return new Promise<IReceiptHistoryComponent[]>((resolve, reject) => {
            try {
                http.post("/api/receipt/getReceiptHistoryAndDetail", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;

                    console.log(data.returnCode);
                    if (data.returnCode === 200) {
                        const receiptHistory = data.data as IReceiptHistoryComponent[];
                        console.log(JSON.stringify(receiptHistory))
                        resolve(receiptHistory);
                    } else {
                        reject(response);
                    }
                }).catch(response => {
                    reject(response);
                });
            } catch(e) {
                reject(e);
            }
        });

    }

    /**
     * 領収書発行
     */
    requestEditReceipt(targetStore: IUserTragetStoreInfo, receipt_id: number){
        const rdStore = useRegistrationStore();

        const registrationReceiptDetailDialogStore = useRegistrationReceiptDetailDialogStore();

        const receipt = registrationReceiptDetailDialogStore.receipt;

        const reqReceipt = JSON.parse(JSON.stringify(receipt));


        const sendData: { [key: string]: any; } = {
            id: receipt_id,
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id,
            receipt: reqReceipt,
            previewReceiptId: registrationReceiptDetailDialogStore.previewReceiptId,
            receiptType: registrationReceiptDetailDialogStore.receiptType
        };

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/issueReceipt", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                    console.log(data.returnCode);
                    resolve(data);
                }).catch(response => {
                    console.log("issueReceipt Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });

    }

    public downloadDetailsPdf(url: string) {

        const sendData: { [key: string]: any; } = {
            url
        };
        
        return new Promise((resolve, reject) => {
            try {
                http.post<BlobPart>("/api/registration/downloadDetailsPdf", CConvertor.toSnakeCaseObject(sendData))
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (data.returnCode === 200) {
                            resolve(data);
                        } else {
                            reject(data);
                        }
                        resolve(data);
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        })
    }

    getUsedRoomAndPlan(data: any,targetStore: any) {
        const sendData = {
            date: data.roomDate,
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            current_room_id: data.assignedRoomId
        }
        return new Promise((resolve, reject) => {
            try {
                http.post<BlobPart>("/api/registration/getUsedRoomAndPlan", CConvertor.toSnakeCaseObject(sendData))
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (data.returnCode === 200) {
                            resolve(data);
                        } else {
                            reject(data);
                        }
                        resolve(data);
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        })
    }

    attachmentUpload(files: any) {
        const rdStore = useRegistrationStore();
        const formData = new FormData();
        formData.append("file", files.files[0]);
        formData.append("b_basic_information_id", rdStore.bBasicInformation.id ? rdStore.bBasicInformation.id.toString():'');
        formData.append("b_room_unit_id", rdStore.getRoomUnit.id ? rdStore.getRoomUnit.id.toString():'');
        const sendData = formData;
        return new Promise<IBFileComponent>((resolve, reject) => {
            try {
                http.post("/api/registration/attachment/upload", sendData)
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IBFileResponse;
                        if (data.returnCode === 200) {
                            const bFIle = data.data as IBFileComponent;
                            resolve(bFIle);
                        } else {
                            reject(data);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        })
    }

    attachmentDelete(file: any) {
        const rdStore = useRegistrationStore();
        const sendData = {
            id: file.id,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id
        }
        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/attachment/delete", CConvertor.toSnakeCaseObject(sendData))
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (data.returnCode === 200) {
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        })
    }

    attachmentDownload(file: any) {
        const rdStore = useRegistrationStore();
        const sendData = {
            id: file.id,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id
        }
        return new Promise((resolve, reject) => {
            try {
                http.post<BlobPart>("/api/registration/attachment/download", CConvertor.toSnakeCaseObject(sendData))
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IBFileResponse;
                        if (data.returnCode === 200) {
                            resolve(data.data);
                        } else {
                            reject(data);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        })
    }

    attachmentAll() {
        const rdStore = useRegistrationStore();
        const sendData = {
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id
        }
        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/attachment/all", CConvertor.toSnakeCaseObject(sendData))
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IBFileResponse;
                        if (data.returnCode === 200) {
                            resolve(data.data as IBFileComponent[]);
                        } else {
                            reject(data);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        })
    }

    /**
     * 売上計上日を取得する
     */
    getSalesDate () {
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;

        const sendData = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
        }
        return new Promise<IInternalResponse>((resolve, reject) => {
            try {
                http.post("/api/registration/getSalesDate", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (res.returnCode === 200) {
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        console.log("getSalesDate Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    searchGuestByCondition(guestInfo: any,targetStore: any) {
        const sendData = {
            guest_name: guestInfo.name,
            guest_phone: guestInfo.phone,
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId
        }
        console.log(sendData)
        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/searchGuestByCondition", CConvertor.toSnakeCaseObject(sendData))
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (data.returnCode === 200) {
                            const customers = data.data;
                            resolve(customers);
                        } else {
                            reject(data);
                        }
                        resolve(data);
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        })
    }

    searchGuestById(data: any,targetStore: any) {
        const sendData = {
            id: data.id,
            phone: data.phone,
            name: data.name,
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId
        }
        console.log(sendData)
        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/searchGuestById", CConvertor.toSnakeCaseObject(sendData))
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (data.returnCode === 200) {
                            const customers = data.data;
                            resolve(customers);
                        } else {
                            reject(data);
                        }
                        resolve(data);
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        })
    }

    saveCustomer(customer: any,targetStore: any,passport: any, authInfo: ISubmitAuthComponent ) {
        const sendData = {...customer};
        // sendData.p_id = passport.id;
        // sendData.p_issuing_country = passport.issuingCountry;
        // sendData.p_passport_no = passport.passportNo;
        // sendData.p_surname = passport.surname;
        // sendData.p_given_name = passport.givenName;
        // sendData.p_nationality = passport.nationality;
        // sendData.p_date_of_birth = passport.dateOfBirth;
        // sendData.p_sex = passport.sex;
        // sendData.p_registered_domicile = passport.registeredDomicile;
        // sendData.p_date_of_issue = passport.dateOfIssue;
        // sendData.p_date_of_expiry = passport.dateOfExpiry;
        sendData.store_id = targetStore.storeId;
        sendData.company_id = targetStore.companyId;
        sendData.group_id = targetStore.groupId;
        sendData.submit_user_email = authInfo.email;
        sendData.input_by = authInfo.inputBy;
        sendData.password = authInfo.password;

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/saveCustomer", CConvertor.toSnakeCaseObject(sendData))
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (data.returnCode === 200) {
                            const customer = data.data as ICustomerComponent;
                            resolve(customer);
                        } else {
                            reject(data);
                        }
                        resolve(data);
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        })
    }

    searchCustomerResult(id: any, targetStore: any) {
        const sendData = {
            store_id: targetStore.storeId,
            company_id: targetStore.companyId,
            group_id: targetStore.groupId,
            id
        }
        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/searchCustomerResult", CConvertor.toSnakeCaseObject(sendData))
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (data.returnCode === 200) {
                            const customerHistories = data.data as ICustomerHistoryResultComponent[];
                            resolve(customerHistories);
                        } else {
                            reject(data);
                        }
                        resolve(data);
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        })
    }

    mergeCustomer(customer: any, targetStore: any, customerMeta: any[], authInfo: ISubmitAuthComponent) {
        const customerMetaIds: any[] = [];
        customerMeta.forEach(item => {
            customerMetaIds.push(item.id);
        })
        const sendData = {...customer};
        sendData.store_id = targetStore.storeId;
        sendData.company_id = targetStore.companyId;
        sendData.group_id = targetStore.groupId;
        sendData.customer_meta_id = customerMetaIds;
        sendData.b_basic_information_id = customer.bBasicInformationId;
        sendData.b_guest_information_id = customer.bGuestInformationId;
        sendData.b_room_unit_id = customer.bRoomUnitId;

        sendData.submit_user_email = authInfo.email;
        sendData.input_by = authInfo.inputBy;
        sendData.password = authInfo.password;

        return new Promise((resolve, reject) => {
            try {
                http.post("/api/registration/mergeCustomer", CConvertor.toSnakeCaseObject(sendData))
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (data.returnCode === 200) {
                            resolve(data);
                        } else {
                            reject(data);
                        }
                        resolve(data);
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        })
    }

    getGuestUseName () {
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;
        const rdStore = useRegistrationStore();
        const sendData = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id
        }
        return new Promise<IInternalResponse>((resolve, reject) => {
            try {
                http.post("/api/registration/qam/getUseName", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (res.returnCode === 200) {
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        });
    }

    getQams (searchData: any) {
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;
        const rdStore = useRegistrationStore();
        const sendData: any = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id,
            b_guest_information_id: searchData.guest,
            qam_type: searchData.category,
            status: searchData.status,
            important: searchData.important
        }
        return new Promise<IInternalResponse>((resolve, reject) => {
            try {
                http.post("/api/registration/qam/getQams", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (res.returnCode === 200) {
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        });
    }

    saveQam(data: any, authInfo: ISubmitAuthComponent) {
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;
        const rdStore = useRegistrationStore();
        const sendData: any = {
            id: data.id?data.id:null,
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            b_basic_information_id: rdStore.bBasicInformation.id,
            b_room_unit_id: rdStore.getRoomUnit.id,
            b_guest_information_id: data.bGuestInformationId,
            qam_type: data.qamType,
            status: data.status,
            importance: data.importance,
            detail: data.detail,
            machine_no: authStore.machineId!,
            planned_store_id: data.plannedStoreId,
            submit_user_email: authInfo.email,
            input_by: authInfo.inputBy,
            password: authInfo.password
        }
        if (data.plannedDatetime) {
            sendData.planned_datetime = format(new Date(data.plannedDatetime), 'yyyy/MM/dd HH:mm:ss');
        }
        if (data.status === 'IN_PROGRESS') {
            data.inProgressDatetime = new Date(data.inProgressDatetime);
            sendData.in_progress_datetime = format(data.inProgressDatetime, 'yyyy/MM/dd HH:mm:ss');
        } else if (data.status === 'COMPLETED') {
            data.completedDatetime = new Date(data.completedDatetime);
            sendData.completed_datetime = format(data.completedDatetime, 'yyyy/MM/dd HH:mm:ss');
        }
        return new Promise<IInternalResponse>((resolve, reject) => {
            try {
                http.post("/api/registration/qam/saveQam", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (res.returnCode === 200) {
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        });
    }

    deleteQam(id: number, authInfo: ISubmitAuthComponent) {
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;
        const sendData = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            id: id,
            submit_user_email: authInfo.email,
            input_by: authInfo.inputBy,
            password: authInfo.password
        }
        return new Promise<IInternalResponse>((resolve, reject) => {
            try {
                http.post("/api/registration/qam/deleteQam", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (res.returnCode === 200) {
                            resolve(res.data);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        });
    }

    getQam(id: number) {
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;
        const sendData = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            id
        }
        return new Promise<IInternalResponse>((resolve, reject) => {
            try {
                http.post("/api/registration/qam/getQam", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        if (res.returnCode === 200) {
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        });
    }

    /**
     * レジカード発行
     */
    getRegistrationCard(bRoomUnitIds: number[]) {
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;
        const sendData: { [key: string]: any; } = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            b_room_unit_ids: bRoomUnitIds
        };

        return new Promise<IInternalResponse>((resolve, reject) => {
            try {

                console.log('request getRegistrationCard')
                http.post("/api/registration/registrationCard/get", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        
                        if (res.returnCode === 200) {
                            resolve(res);
                        } else {
                            reject(response);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        });
    }

    getTravelAgencyBookingNumberFrom(travelAgencyBookingNumber: string) {
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;
        const sendData: { [key: string]: any; } = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            travelAgencyBookingNumber
        };

        return new Promise<IInternalResponse>((resolve, reject) => {
            try {

                http.post("/api/registration/getTravelAgencyBookingNumberFrom", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        
                        if (res.returnCode === 200) {
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        reject(response);
                    });
            } catch(e) {
                reject(e);
            }
        });
    }
}
