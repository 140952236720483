import i18n from '@/locale/i18n';
export class ToastControl {

    public static SuccessMessageWithSummary = (summary : string, detail : string) => {
        return { severity: 'success', summary: summary, detail: detail, life: 3000 };
    }

    public static ErrorMessageWithSummary = (summary : string, detail : string) => {
        return { severity: 'error', summary: summary, detail: detail, life: 3000 };
    }

    public static SuccessMessage = (detail : string) => {
        return { severity: 'success', summary: 'Successful', detail: detail, life: 3000 };
    }

    public static ErrorMessage = (detail : string) => {
        return { severity: 'error', summary: 'Fail', detail: detail, life: 3000 };
    }

    public static ErrorMessageNoLife = (detail : string) => {
        return { severity: 'error', summary: 'Fail', detail: detail };
    }

    public static WarningMessage = (detail : string) => {
        return { severity: 'warn', summary: 'Warn', detail: detail};
    }

    public static ErrorMessageWithSystemError = () => {
        const t = i18n.global.t;
        return this.ErrorMessage(t('ERROR_MESSAGE.HOS-00000-1'));
    }

    public static SuccessMessageWithCreateSuccessful = () => {
        const t = i18n.global.t;
        return this.SuccessMessage(t('MESSAGE.CREATE_SUCCESS_MESSAGE'));
    }

    public static SuccessMessageWithUpdateSuccessful = () => {
        const t = i18n.global.t;
        return this.SuccessMessage(t('MESSAGE.UPDATE_SUCCESS_MESSAGE'));
    }

    public static SuccessMessageWithDeleteSuccessful = () => {
        const t = i18n.global.t;
        return this.SuccessMessage(t('MESSAGE.DELETE_SUCCESS_MESSAGE'));
    }
}

