// defineStoreをインポート
import { IRoomTypeComponent } from '@/interface/component/master/i-room-type-component'
import { defineStore } from 'pinia'

export const useRoomTypeStore = defineStore('roomType', {
    state: () => ({
        roomTypes: [] as IRoomTypeComponent[],
    }),
    actions: {
    },
    // persist: {
    //     key: 'roomType',
    //     storage: localStorage,
    // }
})

