import { defineStore } from 'pinia'
import { IFreeMemoDropDownComponent } from '@/interface/component/master/i-free-memo-component';

export const useFreeMemoStore = defineStore('freeMemo', {
    state: () => ({
        freeMemos: [] as IFreeMemoDropDownComponent[],
    }),
    
    actions: {
        getFreeMemo (id: number) {
            const resFind = this.freeMemos.find((data) => data.id === id);
            return resFind;
        }
    },
    // persist: {
    //     key: 'freeMemo',
    //     storage: localStorage,
    // }
})

