// defineStoreをインポート

// import axios from 'axios';
// import http from "@/http-common";
// import { CConvertor } from "@/utils/c-convertor";
// import { IInternalResponse } from "@/interface/api/i-internal-response";
// import { ILoginResponse } from "@/interface/api/auth/i-login-response";
// import { IStaffLoginResponse } from "@/interface/api/auth/i-staff-login-response";
import Echo from 'laravel-echo';
import { IMenus } from '@/interface/serverData/common/i-menus';
import { IStaff } from '@/interface/serverData/store/i-staff';
import { IUserInfo, IUserTragetStoreInfo } from '@/interface/serverData/common/i-user-info';
import { defineStore } from 'pinia';
import { IStoreComponent } from '@/interface/component/master/i-store-component';
// interface State {
//     isAuth: boolean
//     user: IUserInfo | null
//     staff: IStaff | null
// }

// main is the name of the store. It is unique across your application
// and will appear in devtools
export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuth: false,
    user: null as IUserInfo | null,
    menus: null as IMenus[] | null,
    staff: null as IStaff | null,
    targetStore: null as IUserTragetStoreInfo | null, // For target store
    bussinessDate: null as string | null,
    salesDate: null as string | null,
    spinner: false,
    machineId: null as string | null,
    isMachineNoUse: false,
    isSessionExpired: false,
    tokenVal: null as string | null,
    // laravelEcho: null as Echo | null,
    isSavePermission: true,
    
    currentStoreInformation: null as IStoreComponent | null
  }),
  // state: (): State => {
  //     return {
  //         isAuth: false,
  //         user: null,
  //         staff: null
  //     }
  // },
  // optional getters
  getters: {
    Authenticated: (state) => state.isAuth,
    loggedUser: (state) => state.user,
    isSpinnerShow: (state) => state.spinner,
    userMenus: (state) => state.menus,
    isMachineIdUse: (state) => {
      state.isMachineNoUse = false;
      try {
        state!.user!.userStores.forEach((store) => {
          if (store.storeId === state.targetStore!.storeId) {
            if (store.isMachineNoUse == 'YES') {
              state.isMachineNoUse = true;
              return;
            }
          }
        });
      } catch (error) {
        state.isMachineNoUse = false;
      }

      return state.isMachineNoUse;
    },

    savePermission: (state) => {
      state.isSavePermission = false;
      try {
        state!.user!.userStores.forEach((store) => {
          if (store.storeId === state.targetStore!.storeId) {
            if (store.isSavePermission == 'NO') {
              state.isSavePermission = false;
              return;
            } else {
              state.isSavePermission = true;
              return;
            }
          }
        });
      } catch (error) {
        state.isSavePermission = true;
      }
    
      return state.isSavePermission;
    },

    hasClientId: (state) => {
      // 端末ID利用可否、端末IDを保持しているか判定
      console.log('state.machineId = ', state.machineId);

      if (!state.isMachineNoUse) {
        console.log('auth.hasClientId 端末ID使用しない。 true');
        return true;
      }

      if (state.machineId === null || state.machineId === undefined) {
        console.log('auth.hasClientId 端末ID使用する。ID未取得 false');
        return false;
      } else {
        console.log('auth.hasClientId 端末ID使用する。ID取得済み true');
        return true;
      }
    },
    isSessionExpire: (state) => state.isSessionExpired,
  },
  // optional actions
  actions: {
    showSpinner() {
      this.spinner = true;
    },
    hideSpinner() {
      this.spinner = false;
    },
    setDefaultState() {
      this.user = null;
      this.menus = null;
      this.staff = null;
      this.targetStore = null;
      this.bussinessDate = null;
      this.salesDate = null;
      this.currentStoreInformation = null;
    },
    sessionUnExpired() {
      this.isSessionExpired = false;
    },
    sessionExpired() {
      this.isSessionExpired = true;
    },

    //Unregister all websockets channels. by Charles-G
    // unregisterAllWSChannel() {
    //   if (this.laravelEcho) {
    //     this.laravelEcho.leaveAllChannels();
    //   }
    // },

    //Used for register websockets listener. by Charles-G
    // registerWSListener() {
      // //Only user logged in the ws channel could be created
      // if (this.user) {
      //   if (this.laravelEcho) {
      //     this.laravelEcho.leaveAllChannels();
      //   }
      //   const pusher = require('pusher-js');
      //   this.laravelEcho = new Echo({
      //     broadcaster: process.env.VUE_APP_LARAVAL_ECHO_BROADCASTER,
      //     key: process.env.VUE_APP_LARAVAL_ECHO_KEY,
      //     cluster: process.env.VUE_APP_LARAVAL_ECHO_CLUSTER,
      //     wsHost: process.env.VUE_APP_LARAVAL_ECHO_WS_HOST,
      //     wsPort: Number(process.env.VUE_APP_LARAVAL_ECHO_WS_PORT),
      //     forceTLS: Boolean(process.env.VUE_APP_LARAVAL_ECHO_FORCE_TLS == 'true'),
      //     disableStats: Boolean(process.env.VUE_APP_LARAVAL_ECHO_DISABLE_STATS == 'true'),
      //     authEndpoint: process.env.VUE_APP_LARAVAL_ECHO_AUTH_END_POINT,
      //     auth: {
      //       headers: {
      //         Authorization: 'Bearer ' + this.user.tokenVal, //Bearer is the prefix
      //       },
      //     },
      //   });
      //   const channelName = `${process.env.VUE_APP_LARAVAL_ECHO_EVENT_PREFIX}.${this.user.groupId}.${this.user.companyId}.${this.user.storeId}`;

      //   //listen the business date updated event.
      //   this.laravelEcho.private(channelName).listen('BusinessDateUpdated', (d: any) => {
      //     console.log('BUSINESSDATEUPDATED:', d.businessDate);
      //     this.bussinessDate = d.businessDate;
      //   });

        // //listen the store setting updated event.
        // this.laravelEcho.private(channelName).listen('StoreSettingUpdated', (d: any) => {
        //   console.log('STORESETTINGUPDATED:', d.isSavePermission);
        //
        //   this.user!.userStores.forEach((store) => {
        //     if (store.storeId === d.storeId) {
        //       store.isSavePermission = d.isSavePermission;
        //     }
        //   });
        // });
      // }
    // },
    // unregisterWSListener() {},
  },
  persist: {
    key: 'auth',
    storage: localStorage,
  },
});
