import http from "@/http-common";
import { IInternalResponse } from "@/interface/api/i-internal-response";
// HOTELOS_DEV-215 領収書ロゴ start
import { IStoreShowResponse, IStoreEditResponse, IStoreGetSequencesResponse, IStoreEditSequencesResponse, IStoreLogoUploadResponse, ISstoreLogoResponse, IStoreDropdownListResponse } from "@/interface/api/master/i-store-response";
import { IStoreComponent, IStoreSequenceSettingComponent, IStoreLogoUploadEventComponent } from "@/interface/component/master/i-store-component";
// HOTELOS_DEV-215 領収書ロゴ end
import { IUserTragetStoreInfo } from "@/interface/serverData/common/i-user-info";
import { CConvertor } from "@/utils/c-convertor";
import { ISubmitAuthComponent } from "@/interface/component/common/i-submit-auth-component";

export default class StoreService {


    // HOTELOS_DEV-215 領収書ロゴ start
    /**
     * show a store logo upload
     */
     upload(event :IStoreLogoUploadEventComponent) {
        const data = CConvertor.toCamelCaseObject(JSON.parse(event.xhr.response)) as IStoreLogoUploadResponse;
        if (data.returnCode === 200) {
          return data.data as ISstoreLogoResponse
        } else {
           console.log("StoreService upload store logo Error");
        }
     }
   // HOTELOS_DEV-215 領収書ロゴ end

    /**
     * show a store
     */
    show(targetStore: IUserTragetStoreInfo) {

        console.log("StoreService show START");
        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
        };

        return new Promise<IStoreComponent>((resolve, reject) => {
            try {

                http.post("/api/master/store/show", sendData)
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IStoreShowResponse;
                        console.log(data.returnCode);
                        if (data.returnCode === 200) {
                            const store = data.data as IStoreComponent;
                            console.log(JSON.stringify(store))
                            resolve(store);
                        } else {
                            reject(response);
                        }
                    }).catch(response => {
                        console.log("StoreService show Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    /**
     * Edit a Store
     */
    edit(store: IStoreComponent, authInfo: ISubmitAuthComponent) {

        console.log("StoreService edit START");
        const sendData: { [key: string]: any; } = {
            store_group_id: store.groupId,
            store_company_id: store.companyId,
            group_id: store.groupId,
            company_id: store.companyId,
            store_id: store.companyId,
            id: store.id,
            name: store.name,
            name_kanji: store.nameKanji,
            name_e: store.nameE,
            store_cd: store.storeCd,
            tel: store.tel,
            fax: store.fax,
            email: store.email,
            zipcode: store.zipcode,
            address1: store.address1,
            address2: store.address2,
            url: store.url,
            manager_name: store.managerName,
            manager_department: store.managerDepartment,
            manager_tel: store.managerTel,
            manager_email: store.managerEmail,
            check_in_time: store.checkInTime,	// デフォルトチェックイン時間
            check_out_time: store.checkOutTime,	//	デフォルトチェックアウト時間
            consumption_tax_fraction: store.consumptionTaxFraction,	//	消費税の端数処理
            // HOTELOS_DEV-215 領収書ロゴ start
            logo_path: store.logoPath,  // ロゴパス
            // HOTELOS_DEV-215 領収書ロゴ start
            submit_user_email: authInfo.email,
            input_by: authInfo.inputBy,
            password: authInfo.password,

            password_min_length: store.storeSetting?.passwordMinLength,
            password_pattern: store.storeSetting?.passwordPattern
        };

        return new Promise<IStoreComponent>((resolve, reject) => {
            try {

                http.post("/api/master/store/edit", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IStoreEditResponse;
                        console.log(res.returnCode);
                        if (res.returnCode === 200) {
                            const store = res.data as IStoreComponent;
                            console.log(JSON.stringify(store))
                            resolve(store);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        console.log("StoreService edit Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    /**
     * show a store
     */
    getSequences(targetStore: IUserTragetStoreInfo) {

        console.log("StoreService getSequences START");
        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
        };

        return new Promise<IStoreSequenceSettingComponent[]>((resolve, reject) => {
            try {

                http.post("/api/master/store/getSequences", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IStoreGetSequencesResponse;
                        console.log(res.returnCode);
                        if (res.returnCode === 200) {
                            const store = res.data as IStoreSequenceSettingComponent[];
                            console.log(JSON.stringify(store))
                            resolve(store);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        console.log("StoreService getSequences Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    /**
     * 
     * @param targetStore 
     * @param sequence 
     */
    editSequences(targetStore: IUserTragetStoreInfo, sequence: IStoreSequenceSettingComponent, authInfo: ISubmitAuthComponent) {

        console.log("StoreService editSequences START");
        const sendData: { [key: string]: any; } = {
            id: sequence.id,
            group_id: targetStore.groupId,
            company_id: targetStore.companyId,
            store_id: targetStore.storeId,
            prefix_type: sequence.prefixType,
            prefix: sequence.prefix,
            start_number: sequence.startNumber,
            present_number: sequence.presentNumber,
            submit_user_email: authInfo.email,
            input_by: authInfo.inputBy,
            password: authInfo.password
        };

        return new Promise<IStoreSequenceSettingComponent>((resolve, reject) => {
            try {

                http.post("/api/master/store/editSequences", sendData)
                    .then(response => {
                        const res = CConvertor.toCamelCaseObject(response.data) as IStoreEditSequencesResponse;
                        console.log(res.returnCode);
                        if (res.returnCode === 200) {
                            const store = res.data as IStoreSequenceSettingComponent;
                            console.log(JSON.stringify(store))
                            resolve(store);
                        } else {
                            reject(res);
                        }
                    }).catch(response => {
                        console.log("StoreService editSequences Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });

    }

    getDropdownList(targetStore: IUserTragetStoreInfo) {

        console.log("StoreService getDropdownList START");
        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId
        };

        return new Promise<IStoreComponent[]>((resolve, reject) => {
            try {
                http.post("/api/master/store/getDropdownList", sendData)
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IStoreDropdownListResponse;
                        console.log(data.returnCode);
                        if (data.returnCode === 200) {
                            const store = data.data as IStoreComponent[];
                            console.log(JSON.stringify(store))
                            resolve(store);
                        } else {
                            reject(response);
                        }
                    }).catch(response => {
                        console.log("StoreService getDropdownList Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }
}