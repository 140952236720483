import axios, { AxiosInstance } from "axios";
import router from "./router";
import { useAuthStore } from "@/store/auth";

const apiClient: AxiosInstance = axios.create({
  // baseURL: "http://localhost:8000",
  baseURL: process.env.VUE_APP_BASE_API_URL,
  withCredentials: true,
  xsrfHeaderName: "X-CSRF-Token", // 変更の必要があればここを修正？ クッキーからの取得を追加する必要あり。axiosはCookieにXSRF-TOKENがあると、リクエスト時に「X-CSRF-Token」でヘッダを送るようになっている。
  headers: {
    "Content-type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": "*",
  },
});

// axios.interceptors.response.use(function (response) {
//     return response;
//     }, function (error) {
//     if(error.response.status === 401) {
//         // redirect to login page
//         window.location.href = "/login";
//     }
//     return Promise.reject(error);
//   });

apiClient.interceptors.request.use((request) => {
  console.log("axios.interceptors.request = " + JSON.stringify(request));
  return request;
});

apiClient.interceptors.response.use(
  (response) => {
    console.debug("axios.interceptors.response.status = " + response.status);

    try {
      const { common_header } = response.data;
      if (common_header) {
        const authStore = useAuthStore();
        authStore.bussinessDate = common_header.business_date ?? null;
        authStore.salesDate = common_header.sales_date ?? null;

        try {
          authStore.user!.userStores.forEach((store) => {
            if (store.storeId === authStore.targetStore!.storeId) {
              store.isSavePermission = common_header.save_permission;
            }
          });
        } catch (error) {
          authStore.isSavePermission = true;
        }
      }
    } catch {
      console.log("RESPONSE:", response);
    }

    return response;
  },
  async (error) => {
    console.log("axios.interceptors.response.error = " + JSON.stringify(error));
    const status = error.response.status;

    // Session expires
    if (status === 419 || status === 401) {
      const authStore = useAuthStore();
      authStore.sessionExpired();
      router.push({ path: "/login" });
    }

    return Promise.reject(error);
  }
);

export default apiClient;
