import { differenceInDays, format, startOfDay } from "date-fns"

export class DateControl {

    /**
     * DATE_FORMAT_yyyy/MM/dd
     */
    public static DATE_FORMAT_1 = 'yyyy/MM/dd';

    /**
     * DATE_FORMAT_yyyy/MM/dd HH:mm
     */
    public static DATE_FORMAT_2 = 'yyyy/MM/dd HH:mm';

    /**
     * 日数計算
     * @param dateLeft 
     * @param dateRight 
     */
    public static differenceInDaysWithStartOfDay = (dateLeft: Date, dateRight: Date) => {

        return differenceInDays(startOfDay(dateLeft), startOfDay(dateRight))
    }

    /**
     * デフォルトチェックイン設定時刻
     * @param date
     */
    public static getDefaultCheckInTime = (date: Date, time?: string) => {

        let defaultTime;

        if (!time) {
            defaultTime = '15:00';
        }

        return new Date(format(date, 'yyyy-MM-dd') + ' ' + defaultTime);
    }

    /**
     * デフォルトチェックアウト設定時刻
     * @param date
     */
    public static getDefaultCheckOutTime = (date: Date, time?: string) => {

        let defaultTime;

        if (!time) {
            defaultTime = '10:00';
        }

        return new Date(format(date, 'yyyy-MM-dd') + ' ' + defaultTime);
    }

    /**
     * Date convert to String 
     * @param date date
     * @param formatStr format eg yyyy/MM/dd
     */
    public static formatDateToString = (date: Date, formatStr: string) => {
        return format(date, formatStr);
    }

    /**
     * Date convert to String yyyy/MM/dd
     * @param date date
     */
    public static formatDateToStringForFormat1 = (date: Date) => {
        return this.formatDateToString(date, this.DATE_FORMAT_1);
    }

    /**
     * Date convert to String yyyy/MM/dd HH:mm
     * @param date date
     */
    public static formatDateToStringForFormat2 = (date: Date) => {
        return this.formatDateToString(date, this.DATE_FORMAT_2);
    }
}