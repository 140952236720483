export class StringControl {
    /**
     * 文字列空判断
     * @param str 
     */
    public static isEmpty = (str?: string) => {
        if (str === null || str === undefined || str === '') {
            return true;
        } else {
            return false
        }
    }

    public static dateStringIsEmpty = (date?: Date) => {
        if (date === null || date === undefined) {
            return true;
        } else if (this.isEmpty(date.toString())){
            return true;
        } else {
            return false;
        }
    }

    public static numberTypeIsEmpty = (num? : number) => {
        if (num === null || num === undefined || num === 0) {
            return true;
        } else {
            return false
        }
    }

    public static numberIsNull = (num? : number) => {
        if (num === null || num === undefined) {
            return true;
        } else {
            return false
        }
    }

    /**
     * 全角カタカナ、半角英数
     */
    public static isZenkakuKanaAndEnNum = (str?: string) => {
        if (this.isEmpty(str)) {
            return false;
        } else {
            const reg = /^[ァ-ヶー0-9a-zA-Z]+$/;
            if (str!.match(reg)) {
                return true;
            } else {
                return false;
            }

        }
    }

    /**
     * 郵便番号フォーマットチェック
     * @param code 郵便番号
     * @param hyphen ハイフンあり
     */
    public static isPostalCode = (code: string | undefined, hyphen: boolean) => {
        if (this.isEmpty(code)) {
            return false;
        } else {
            let reg;
            if (hyphen) {
                reg = /^[0-9]{3}-[0-9]{4}$/;
            } else {
                reg = /^[0-9]{7}$/;
            }
            
            if (code!.match(reg)) {
                return true;
            } else {
                return false;
            }
        }
    }

    public static isPositiveInteger = (val: number) => {
        let str = String(val);
        str = str.trim();
        if (!str) {
            return false;
        }
        str = str.replace(/^0+/, "") || "0";
        const n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    };


    public static formatCurrency = (fixedExpenses: number) => {
        if(fixedExpenses) {
            return fixedExpenses.toLocaleString('ja-JP');
        } else {
            return 0;
        }
    };

    public static formatCurrencyWithPixel = (fixedExpenses: number) => {
        if(fixedExpenses) {
            return fixedExpenses.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' });
        } else {
            return 0;
        }
    };
}

