import { defineStore } from 'pinia'
import { ISalesRouteComponent } from '@/interface/component/master/i-sales-route-component';

export const useSalesRouteStore = defineStore('salesRoute', {
    state: () => ({
        salesRoutes: [] as ISalesRouteComponent[],
    }),
    
    actions: {
        getSalesRoute(id: number){
            const resFind = this.salesRoutes.find((data) => data.id === id);
            return resFind;
        },

        getSalesRouteName(id: number) {
            const resFind = this.salesRoutes.find((data) => data.id === id);
            if (resFind) {
                return resFind.name;
            } else {
                return '';
            }
        },
    },
    // persist: {
    //     key: 'salesRoute',
    //     storage: localStorage,
    // }
})

