import { toRefs, reactive, computed } from 'vue';

const layoutConfig = reactive({
    ripple: false,
    darkTheme: false,
    inputStyle: 'outlined',
    menuMode: 'static',
    theme: 'lara-light-indigo',
    scale: 14,
    activeMenuItem: 'ダッシュボード'
});

const layoutState = reactive({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    leftbarMenuVisible: false
});

export function useLayout() {
    const changeThemeSettings = (theme: any, darkTheme: any) => {
        layoutConfig.darkTheme = darkTheme;
        layoutConfig.theme = theme;
    };

    const setScale = (scale: any) => {
        layoutConfig.scale = scale;
    };

    const setActiveMenuItem = (item: any) => {
        layoutConfig.activeMenuItem = item;
    };

    const onMenuToggle = () => {
        if (layoutConfig.menuMode === 'overlay') {
            layoutState.overlayMenuActive = !layoutState.overlayMenuActive;
        }

        if (window.innerWidth > 991) {
            layoutState.staticMenuDesktopInactive = !layoutState.staticMenuDesktopInactive;
        } else {
            layoutState.staticMenuMobileActive = !layoutState.staticMenuMobileActive;
        }
    };

    const onLeftbarMenuToggle = () => {
        console.log('onLeftbarMenuToggle')
        console.log(layoutState.leftbarMenuVisible)
        layoutState.leftbarMenuVisible = !layoutState.leftbarMenuVisible;
        console.log(layoutState.leftbarMenuVisible)
    };

    const showLeftbarMenu = () => {
        layoutState.leftbarMenuVisible = true;
    };

    const hideLeftbarMenu = () => {
        layoutState.leftbarMenuVisible = false;
    };

    const isSidebarActive = computed(() => layoutState.overlayMenuActive || layoutState.staticMenuMobileActive);

    const isDarkTheme = computed(() => layoutConfig.darkTheme);

    return { 
        layoutConfig: toRefs(layoutConfig), 
        layoutState: toRefs(layoutState), 
        changeThemeSettings, 
        setScale, 
        onMenuToggle, 
        isSidebarActive, 
        isDarkTheme, 
        setActiveMenuItem , 
        onLeftbarMenuToggle,
        showLeftbarMenu,
        hideLeftbarMenu
    };
}
