import http from "@/http-common";
import { IHolidayListResponse, IHolidayResponse } from "@/interface/api/master/i-holiday-response";
import { IUserTragetStoreInfo } from "@/interface/serverData/common/i-user-info";
import { CConvertor } from "@/utils/c-convertor";

export default class HolidayService {

    /**
     * Get holidays list
     */
    list() {
        console.log("HolidayService list START");
        return new Promise<IHolidayResponse[]>((resolve, reject) => {
            try {
                http.post("/api/master/plan/holiday/list")
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IHolidayListResponse;
                        console.log(data.returnCode);
                        if (data.returnCode === 200) {
                            const holidays = data.holidays as IHolidayResponse[];
                            console.log(JSON.stringify(holidays))
                            resolve(holidays);
                        } else {
                            reject(response);
                        }
                    }).catch(response => {
                        console.log("HolidayService getHolidays  Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }
}