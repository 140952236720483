// defineStoreをインポート
import {defineStore} from 'pinia'
// import axios from 'axios';
import { IRegistrationListCondtionComponent } from '@/interface/component/registrationList/i-registration-list-component';

export const useRegistrationListStore = defineStore('registrationList', {
    state: () => ({
        condition: initializeComponent() as IRegistrationListCondtionComponent,
        // searchResult: [] as IRegistrationListComponent[],
        detailBookingBasicInformationId: 0 as number,
        checkInDate: new Date() as Date,
        checkOutDate: new Date() as Date,
        assignedRoomNumber: '' as String,
        assignedRoomId: 0 as Number,
        assignedRoomTypeId: 0 as number,
        assignedRoomTypeName: '' as String,
        roomTypeName: '' as String,
        roomTypeCode: '' as String,
        roomTypeId: 0 as number,
        Flag: false as boolean,
        searchFlag: false as boolean,
        detailSearchFlag: false as boolean,
        showDetailCondition: false as boolean,
        easySearchStatus: '' as any,
        editRegulations: false as boolean,
        userName: '' as String,
        routerFrom: {} as any,
        roomUnitIndex: 0 as number,
        roomUnitId: 0 as number,
        multiToFlag: false as boolean,
        dashBoardDump: false as boolean,
    }),
    actions: {
        initializeCondition() {
            return initializeComponent()
        },
    }
})

function initializeComponent(): IRegistrationListCondtionComponent {
    return {
        id: 0,
        groupId: 0,	//	グループID
        companyId: 0,	//	会社ID
        storeId: 0,	//	店舗ID
        status: '',	//	予約ステータス
        bookingNo: '',	//	予約番号
        checkInDate: new Date(),	//	チェックイン日
        checkOutDate: new Date(),	//	チェックアウト日
        searchBussessDate: '', //	業務日付
        guestOrGroupNameSingleByte: '',	//	団体名または代表者氏名(半角)
        guestOrGroupNameKanjiName: '',	//	団体名または代表者氏名 漢字
        guestOrGroupPhoneNumber: '',	//	団体名または代表者電話番号
        roomTypeName: '',
        guestOrGroupNameDoubleByte: '',
        guestNameSingleByte: '',
        assignedRoomNumber: '',
        grandTotalPaxCount: 0, //	1泊目のお客様総合計人数
    } as unknown as IRegistrationListCondtionComponent
}
