// defineStoreをインポート
import { defineStore } from 'pinia'
import { IChildFeeMasterComponent } from '@/interface/component/master/i-child-fee-component';

export const useChildFeeStore = defineStore('childFee', {
    state: () => ({
        childFee : {} as IChildFeeMasterComponent,
    }),

    getters: {
        childA70ChildFeeInclude(state) {
            if ((state.childFee.childA70ChildFeeType === 'PERCENTAGE' && state.childFee.childA70IncludeItemsType === 'INCLUDE')
            || state.childFee.childA70ChildFeeType === 'STATIC'
            ) {
                return true
            } else {
                return false
            }
        },

        childB50ChildFeeInclude(state) {
            if ((state.childFee.childB50ChildFeeType === 'PERCENTAGE' && state.childFee.childB50IncludeItemsType === 'INCLUDE')
            || state.childFee.childB50ChildFeeType === 'STATIC'
            ) {
                return true
            } else {
                return false
            }
        },

        childC30ChildFeeInclude(state) {
            if ((state.childFee.childC30ChildFeeType === 'PERCENTAGE' && state.childFee.childC30IncludeItemsType === 'INCLUDE')
            || state.childFee.childC30ChildFeeType === 'STATIC'
            ) {
                return true
            } else {
                return false
            }
        },

        childDnoneChildFeeInclude(state) {
            if ((state.childFee.childDnoneChildFeeType === 'PERCENTAGE' && state.childFee.childDnoneIncludeItemsType === 'INCLUDE')
            || state.childFee.childDnoneChildFeeType === 'STATIC'
            ) {
                return true
            } else {
                return false
            }
        },

        childEnameChildFeeInclude(state) {
            if ((state.childFee.childEnameChildFeeType === 'PERCENTAGE' && state.childFee.childEnameIncludeItemsType === 'INCLUDE')
            || state.childFee.childEnameChildFeeType === 'STATIC'
            ) {
                return true
            } else {
                return false
            }
        },
    },
    
    actions: {
        
    },
    // persist: {
    //     key: 'childFee',
    //     storage: localStorage,
    // }
})

