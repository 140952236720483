// defineStoreをインポート
import { IRoomAssignDropDownComponent } from '@/interface/component/master/i-room-assign-component'
import { defineStore } from 'pinia'

export const useRoomAssignStore = defineStore('roomAssignable', {
    state: () => ({
        roomAssignables: [] as IRoomAssignDropDownComponent[],
        allRooms: [] as IRoomAssignDropDownComponent[],
        needRefreshDate: [] as string[],
        needRefresh: false 
    }),
    actions: {
    }
})

