import { defineStore } from 'pinia'
import { ICountryComponent } from '@/interface/component/registration/i-registration-component';

export const usePrefectureStore = defineStore('prefecture', {
    state: () => ({
        prefectures: [] as ICountryComponent[],
    }),
    
    actions: {
        getPrefectures(id: number){
            const resFind = this.prefectures.find((data) => data.id === id);
            return resFind;
        }
    },
    persist: {
        key: 'prefecture',
        storage: localStorage,
    }
})

